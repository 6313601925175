<!-- <div class="row account-overview-header">
    <div class="col-xs-4 col-sm-5 col-md-7">
      <h3 class="reservation-details">
        {{ "accountOverview.FluReservations.title" | cxTranslate }}
      </h3>
    </div>
  </div>
  <hr />
  <app-flu-content></app-flu-content> -->



<aside class="
  col-12 col-xs-12 col-sm-5 col-md-4
  right-navigation
  float-right
  my-acc-aside
">
  <div class="ad-acc-details displayNo">
    <div class="titletext1" role="heading" aria-level="2">
      {{ "accountOverview.myAccount_label" | cxTranslate }}
    </div>
    <div class="row acc-numb">
      <div class="col-8 col-xs-8 col-md-8 acc-title">
        <img src="/../../../assets/icons/user-account.svg" alt="user account" />
        <!--      <span-->
        <!--        >{{ "accountOverview.account_label" | cxTranslate }} &nbsp;-->
        <!--        {{ accountNumber }}</span-->
        <!--      >-->
      </div>
      <div class="col-4 col-xs-4 col-md-4 acc-title-manage">
        <a (click)="navigateToAccountDetails()" data-di-id="di-id-9b8eeaed-3b366785">{{ "accountOverview.view_label" |
          cxTranslate }}</a>
      </div>
    </div>
    <div>
      <section class="account-Holder-Info-Div row mb-3 pb-3 pl-3">
        <section class="account-Details-Div w-100 pt-1 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
          <section class="account-Name-Label">
            <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.name'|
              cxTranslate}}</label>
          </section>
          <section class="name-Of-Account">
            <span class="account-Name-Span account-Font-Values">{{accountLocName}}</span>
          </section>
        </section>
        <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
          <section class="account-Name-Label">
            <label class="labels-Of-Account account-Font-Values">{{'accountOverview.siret_code'| cxTranslate}}</label>
          </section>
          <section class="name-Of-Account">
            <span class="account-Phone-No account-Font-Values">{{accountNumber}}</span>
          </section>
        </section>
        <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
          <section class="account-Name-Label">
            <label class="labels-Of-Account account-Font-Values">{{'myAcountPandPre.cepUid'| cxTranslate}}</label>
          </section>
          <section class="name-Of-Account">
            <span class="account-Phone-No account-Font-Values">{{sapCustomerId}}</span>
          </section>
        </section>
        <section class="account-Details-Div w-100 col-md-12 col-sm-12 col-lg-12 col-12 col-xl-12">
          <section class="account-Name-Label">
            <label class="labels-Of-Account account-Font-Values">{{'accountOverview.cip_code'| cxTranslate}}</label>
          </section>
          <section class="name-Of-Account">
            <span class="account-Phone-No account-Font-Values">{{cipCode}}</span>
          </section>
        </section>
      </section>
    </div>
  </div>
  <div class="line-divider"></div>
  <section class="displayNo col-xs-12 col-sm-7 col-md-12 quick-order-sec">
    <app-order-products></app-order-products>
  </section>

  <!-- <section class="displayNo col-xs-12 col-sm-7 col-md-12">
  <app-news-updates></app-news-updates>
</section> -->
  <!-- <section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner1"
  ></app-flu-dashboard-banner>
</section>
<section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner2"
  ></app-flu-dashboard-banner>
</section>
<section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner3"
  ></app-flu-dashboard-banner>
</section> -->
  <section class="displayNo banner-Align"></section>
</aside>
