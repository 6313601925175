import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as _ from 'lodash';
import * as moment from 'moment';
import { defineLocale, frLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/services/shared.service';
import { CommonUtils } from '../../shared/utils/common.utils';
import { InvoiceUtils } from '../../shared/utils/invoice.utils';
import { MakeAPaymentService } from '../make-a-payment.service';
import {AccountType} from "../../custom-user-registration/registration.constants";

const symbolNarrow = 'symbol-narrow';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  compNameAdBanner1 = 'InvoiceBannerComponent';
  currentDate = new Date();
  paymentOptionInvoicesDefault = 'Pay Now';
  enablePayment: boolean[] = [];
  enableCredits: boolean[] = [];
  errorMessageStatus: boolean[] = [];
  rejectedRecords: any[] = [];
  isValid = true;
  clicked = false;
  paymentList: any = [];
  closedInvoiceList: any = [];
  showdropdown = false;
  creditList: any = [];
  payment: any;
  myDate: string;
  invoiceItems: any = [];
  creditItems: any = [];
  creditDropDownSource: any[];
  listofSelectedInvoices: any = [];
  indexOfSelectedInvoice: any;
  openCreditInvoice: any;
  closedCreditNotes: any;
  userName: any;
  previousValue: any;
  isAsmEnabled: any;
  disablePaymentAmount = false;
  userSubscription: Subscription;
  creditInvoiceDateSubscription: Subscription;
  closedInvoiceDateSubscription: Subscription;
  csAgentAuthSubscription: Subscription;
  accountIdSubscription: Subscription;
  timeFrameList = ['Tout', '30 jours', '6 mois', '1 an'];
  iconTypes = ICON_TYPE;
  creditOrInvoiceNumber: any;
  fromDate: any;
  toDate: any;
  maxSelectionDate = new Date();
  minSelectionDate: Date;
  timeFrame = '30 jours';
  selectedTimeFrame = '30 jours';
  singleDateError = false;
  differenceInDaysError = false;
  dateError = false;
  filters = { fields: 'DEFAULT', timeFrame: '6' };
  facetsList: any = [{ type: 'timeFrame', value: '6 Months' }];
  dateFormat = 'DD/MM/YYYY';
  unitId: any;
  hospital: boolean;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly makeAPayment: MakeAPaymentService,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly localeService: BsLocaleService,
  ) {
    defineLocale('fr', frLocale);
    this.localeService.use('fr');
    this.minSelectionDate = new Date();
    this.minSelectionDate.setFullYear(this.maxSelectionDate.getFullYear() - 1);
    this.minSelectionDate.setMonth(this.maxSelectionDate.getMonth() - 6);
   }

  ngOnInit(): void {
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    this.invoiceItems = [];
    this.creditItems = [];
    sessionStorage.removeItem('creditData');
    sessionStorage.removeItem('invoicesData');
    localStorage.removeItem('creditData');
    localStorage.removeItem('invoicesData');
    this.csAgentAuthSubscription = this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      this.isAsmEnabled = res ? res : false;
    });
    this.myDate = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
    this.userSubscription = this.user.get().subscribe((res: any) => {
      if (res !== false) {
        this.userName = res?.userName;
        if (this.userName) {
          this.accountIdSubscription = this.makeAPayment.getAccountId(this.userName).subscribe((resp) => {
            if (resp) {
              this.unitId = resp?.defaultB2BUnit?.uid
              this.makeAPayment.getInvoices({
                accountId: resp?.defaultB2BUnit?.uid,
                fields: 'DEFAULT',
                timeFrame: '30',
              });
              this.makeAPayment.getClosedInvoices({
                accountId: resp?.defaultB2BUnit?.uid,
                fields: 'DEFAULT',
                timeFrame: '30',
              });
            }
          });
        }
      }
    });

    this.creditInvoiceDateSubscription = this.makeAPayment.creditInvoiceDate$.subscribe((res) => {
      if (res !== false && res.length !== 0) {
        this.openCreditInvoice = res.openInvoices;
        this.paymentList = InvoiceUtils.getInvoiceTableData(res.openInvoices);
        this.creditDropDownSource = InvoiceUtils.getCreditDropDownSource(
          this.paymentList
        );

        this.creditList = InvoiceUtils.getCreditTableData(
          res.openInvoices,
          this.creditDropDownSource
        );

        this.paymentList = CommonUtils.sortArr(
          'docDate',
          this.paymentList,
          'Date',
          1
        );
        this.creditList = CommonUtils.sortArr(
          'docDate',
          this.creditList,
          'Date',
          1
        );
        this.paymentList.forEach((element) => {
          element.paymentAmount = this.currencyPipe.transform(
            element?.amount,
            'USD',
            symbolNarrow,
            '1.2-2'
          );
        });
        this.cdr.detectChanges();
      }
    });

    this.closedInvoiceDateSubscription = this.makeAPayment.closedInvoiceData$.subscribe((res) => {
      if (res !== false && res.length !== 0) {
        this.closedInvoiceList = InvoiceUtils.getInvoiceTableData(res.closedInvoices);
        this.closedInvoiceList = CommonUtils.sortArr(
          'docDate',
          this.closedInvoiceList,
          'Date',
          1
        );

        this.closedCreditNotes = InvoiceUtils.getCreditTableData(
          res.closedInvoices,
          this.creditDropDownSource
        );

        this.closedCreditNotes = CommonUtils.sortArr(
          'docDate',
          this.closedCreditNotes,
          'Date',
          1
        );

        this.creditDropDownSource = InvoiceUtils.getCreditDropDownSource(
          this.closedInvoiceList
        );

        this.closedInvoiceList.forEach((element) => {
          element.paymentAmount = this.currencyPipe.transform(
            element?.amount,
            'EUR',
            symbolNarrow,
            '1.2-2'
          );
        });

        this.cdr.detectChanges();
      }
    });
  }

  clearToDate() {
    this.toDate = '';
  }

  updateToDate() {
    if (this.fromDate) {
      const today = new Date();
      const next30Days = new Date(this.fromDate);
      next30Days.setDate(next30Days.getDate() + 29);
      if (next30Days > today) {
        this.toDate = today;
      } else if(this.toDate) {
        const previous30Days = new Date(this.toDate);
        previous30Days.setDate(previous30Days.getDate() - 30);
        this.toDate = this.toDate;
      }else {
        this.toDate = next30Days;
      }
    }
 }



  validateDate() {
    this.timeFrame = this.fromDate || this.toDate ? 'All' : this.timeFrame;
    this.updateToDate();
    if (this.fromDate && this.toDate) {
      // Calculate difference in milliseconds
      const differenceInMs = this.toDate.getTime() - this.fromDate.getTime();

      // Convert milliseconds to days
      const differenceInDays = Math.ceil(differenceInMs / (1000 * 3600 * 24));

      // If difference is greater than 30 days, show error
      this.differenceInDaysError = differenceInDays <= 30;



      //this.differenceInDaysError = false;
      if (this.differenceInDaysError) {
        this.differenceInDaysError = false;
      } else {
        this.differenceInDaysError = true;
      }

      this.singleDateError = false;
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
  }

  showFacets(data) {
    const facets = JSON.parse(data);
    delete facets.fields;
    if (facets.fromDate) {
      facets.date = `${moment(this.fromDate).format(
        this.dateFormat
      )} - ${moment(this.toDate).format(this.dateFormat)}`;
      delete facets.fromDate;
      delete facets.toDate;
    }
    if (facets.timeFrame) {
      facets.timeFrame = this.timeFrame;
    }
    this.facetsList = Object.entries(facets).map(([type, value]) => ({
      type,
      value,
    }));
  }


  applyFilter(type?: string) {
    if (this.fromDate && this.toDate) {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
        return false;
      } else {
        this.dateError = false;
      }
    }
    const params = { baseSiteId: 'fr', userId: this.userName };
    this.filters = this.getfilterQuery();
    this.showFacets(JSON.stringify(this.filters));
    // this.service.getOrderHistory(params, this.filters);
    // this.userDataSubscription = this.service.userData$.subscribe((res) => {
    //   if (res !== null) {
    //     this.ordersList = res?.orders;
    //     this.formatOrders();
    //     this.ordersList = CommonUtils.sortArr(
    //       'placed',
    //       this.orders,
    //       'normal',
    //       -1
    //     );
    //     this.cd.detectChanges();
    //     this.selectedTimeFrame = this.timeFrame;
    //     this.selectedStatuses = this.statuses;
    //     if (type === 'mobile') {
    //       this.hide();
    //     }
    //     this.changePageSize();
    //   }
    // });
    return true;
  }

  getfilterQuery() {
    const data: any = {
      accountId: this.unitId,
      fields: 'DEFAULT',
    };
    if (this.creditOrInvoiceNumber) {
      data.creditOrInvoiceNumber = this.creditOrInvoiceNumber;
    }
    if (this.timeFrame && !this.fromDate && !this.toDate) {
      if (this.timeFrame === 'All') {
        data.timeFrame = 'All';
      }
      if (this.timeFrame === '30 jours') {
        data.timeFrame = '30';
      }
      if (this.timeFrame === '6 mois') {
        data.timeFrame = '6';
      }
      if (this.timeFrame === '1 an') {
        data.timeFrame = '1';
      }
    }
    if (this.fromDate && this.toDate) {
      if (this.timeFrame === 'Tout' && data.timeFrame) {
        delete data.timeFrame;
      }
      data.fromDate = moment(this.fromDate).format('DD-MM-YYYY');
      data.toDate = moment(this.toDate).format('DD-MM-YYYY');
    } else if (
      (!this.fromDate && this.toDate) ||
      (this.fromDate && !this.toDate)
    ) {
      this.singleDateError = true;
    } else {
      //no data to return
    }
    this.makeAPayment.getInvoices(data);
    this.makeAPayment.getClosedInvoices(data);
    return data;
  }

  clearFilters() {
    this.facetsList = [];
    this.filters = { fields: 'DEFAULT', timeFrame: 'Tout' };
    this.creditOrInvoiceNumber = undefined;
    this.fromDate = undefined;
    this.toDate = undefined;
    this.timeFrame = '30 jours';
    this.differenceInDaysError = false;
    window.location.reload();

    // this.getOrderList();
    this.getfilterQuery();
  }

  clearDateRange() {
    this.fromDate = undefined;
    this.toDate = undefined;
  }

  // getOrderList() {
  //   this.userInfo = this.user.get().subscribe((userData) => {
  //     this.userId = userData;
  //     if (userData) {
  //       const params = { baseSiteId: 'fr', userId: this.userId.userName };
  //       this.service.getOrderHistory(params, this.filters);
  //       this.userDataSubscription = this.service.userData$.subscribe((res) => {
  //         if (res !== null) {
  //           this.ordersList = res?.orders;
  //           this.formatOrders();
  //           if (this.pageSize > this.ordersList.length) {
  //             this.pageSize = this.ordersList.length;
  //           }
  //           this.ordersList = CommonUtils.sortArr(
  //             'placed',
  //             this.orders,
  //             'date',
  //             -1
  //           );
  //           this.end = this.pageSize;
  //           this.cd.detectChanges();
  //         }
  //       });
  //     }
  //   });
  // }

  invoicePdf(invoiceNo, systemId): void {
    this.makeAPayment.downloadPdfWithSystemId(invoiceNo, systemId);
  }

  creditPdf(creditNo, systemId): void {
    this.makeAPayment.downloadPdfWithSystemId(creditNo, systemId);
  }

  validityCheck(amount): boolean {
    // checking valid amount
    const reg = /^[0-9]*(\.[0-9]{0,2})?$/g;
    return reg.test(amount);
  }

  onInvoiceValueChange(event, index): void {
    const res = this.validityCheck(event);
    if (!!res) {
      this.paymentList[index].invalidAmount = false;
      if (event !== null) {
        const num = event;
        // .trim().replace(/\D/g, '').replace(/^0+/, '');
        // num = !!num ? parseInt(num, 10) : num;
        if (Number(num) >= Number(this.paymentList[index].amount)) {
          this.tableErrorMsg(index);
        } else if (num === '' || num <= 0) {
          this.errorMessageStatus[index] = true;
        } else {
          this.errorMessageStatus[index] = false;
          const val = Number(this.paymentList[index].amount) - Number(num);
          this.paymentList[index].invoiceBalance = val.toString();
        }
      }
    } else {
      const TWO = 2;
      this.paymentList[index].invalidAmount = false;
      this.errorMessageStatus[index] = false;
      if (!isNaN(this.paymentList[index].paymentAmount)) {
        const value = Number(this.paymentList[index].paymentAmount).toFixed(TWO);
        this.cdr.detectChanges();
        const num = event;
        if (Number(num) >= Number(this.paymentList[index].amount)) {
          this.tableErrorMsg(index);
        } else {
          this.paymentList[index].paymentAmount = value.toString();
          const val =
            Number(this.paymentList[index].amount) -
            Number(this.paymentList[index].paymentAmount);
          this.paymentList[index].invoiceBalance = val.toString();
        }
      } else {
        this.paymentList[index].invalidAmount = true;
      }
    }
  }
  tableErrorMsg(index) {
    this.paymentList[index].paymentAmount = this.paymentList[index].amount;
    this.errorMessageStatus[index] = false;
    const val =
      Number(this.paymentList[index].amount) -
      Number(this.paymentList[index].paymentAmount);
    this.paymentList[index].invoiceBalance = val.toString();
    this.cdr.detectChanges();
  }
  onFocus(paymentAmount, index): void {
    if (!!paymentAmount) {
      paymentAmount = paymentAmount.replace('$', '').replace(',', '');
      this.paymentList[index].paymentAmount = paymentAmount;
      this.cdr.detectChanges();
    }
  }
  onBlur(event, index, defaultAmount): void {
    if (event !== null) {
      const res = this.validityCheck(event);
      if (event <= 0 || !res) {
        this.paymentList[index].paymentAmount = this.currencyPipe.transform(
          defaultAmount,
          'USD',
          symbolNarrow,
          '1.2-2'
        );
        this.paymentList[index].invalidAmount = false;
      } else {
        this.paymentList[index].paymentAmount = this.currencyPipe.transform(
          event,
          'USD',
          symbolNarrow,
          '1.2-2'
        );
      }
      this.errorMessageStatus[index] = false;
      this.cdr.detectChanges();
    }
  }
  public parsePaymentType(
    value: any,
    checked: boolean,
    dataSource,
    indexValue,
    creditValueChosen?
  ): void {
    this.disablePaymentAmount = false;
    if (checked) {
      value.errorMsg = true;
      value.invoiceBalance = value.amount;
      dataSource.push(value);
      if (dataSource?.length > 1) {
        this.disablePaymentAmount = true;
        dataSource.forEach(element => {
          element.paymentAmount = this.currencyPipe.transform(
            element.amount,
            'USD',
            symbolNarrow,
            '1.2-2'
          );
        });
      }
      if (creditValueChosen && this.listofSelectedInvoices.length > 0) {
        InvoiceUtils.fetchdropDownSource(
          this.creditList,
          this.creditDropDownSource,
          this.listofSelectedInvoices
        );
      } else {
        if (creditValueChosen && this.listofSelectedInvoices.length === 0) {
          this.clicked =
            !!dataSource && dataSource.length > 1 ? this.clicked : false;
        }
      }
    } else {
      value.invoiceBalance = '';
      dataSource.forEach((element, index) => {
        if (value.docNo === element.docNo) {
          value.checkedAndNotFilledInvoice = false;
          this.validateError(creditValueChosen, element, value, indexValue);
          dataSource.splice(index, 1);
          this.isValid = this.checkRejectedRecords(true) === 0;
          this.cdr.detectChanges();
        }
      });
      if (creditValueChosen) {
        let balanceToBeAdded;

        this.creditList[indexValue].creditsApplied =
          this.creditList[indexValue].amount;
        this.cdr.detectChanges();
      }
    }
  }
  validateError(creditValueChosen, element, value, indexValue) {
    if (!!creditValueChosen) {
      // applicable for credits table
      if (!!element?.selectedInvoice) {
        this.creditDropDownSource.forEach((item) => {
          if (item.docNo === element.invoicenumber) {
            item.pendingBalance += Number(
              this.creditList[indexValue].balanceToBeAdded
            );

            item.includeInList = false;
            InvoiceUtils.fetchdropDownSource(
              this.creditList,
              this.creditDropDownSource,
              this.listofSelectedInvoices
            );
          }
        });
        this.manageSelectedInvoices(
          this.listofSelectedInvoices,
          element.selectedInvoice
        );
      }

      element.selectedInvoice = null; // reset apply to invoice selected value when user unchecks
    } else {
      // applicable for open invoices table
      value.paymentAmount = this.currencyPipe.transform(
        value.amount,
        'USD',
        symbolNarrow,
        '1.2-2'
      );
      this.errorMessageStatus[indexValue] = false;
      this.errorMessageStatus.splice(indexValue, 1);
    }
  }
  // removing selected invoice from list when user unchecks
  manageSelectedInvoices(list, searchValue): void {
    list.splice(
      list.findIndex((element) => {
        return element === searchValue;
      }),
      1
    );
  }

  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }

  initiateCreditPaymentFlow(): void {
    this.clicked = true;
    this.isValid = this.checkRejectedRecords(false) === 0;
    if (!!this.isValid) {
      this.sharedService.setSessionStorage('creditData', this.creditItems);
      if (this.isAsmEnabled) {
        this.sharedService.setLocalStorage('creditData', this.creditItems);
      }
      this.router.navigate(['/', 'openInvoicePayment']);
    }
  }
  initiateInvoicePaymentFlow(): void {
    this.sharedService.setSessionStorage('invoicesData', this.invoiceItems);
    if (this.isAsmEnabled) {
      this.sharedService.setLocalStorage('invoicesData', this.invoiceItems);
    }
  }

  checkRejectedRecords(uncheckedRecord?: boolean): number {
    this.rejectedRecords = _.filter(this.creditItems, (item) => {
      if (item.errorMsg === true && uncheckedRecord) {
        return !!item.checkedAndNotFilledInvoice ? true : false;
      } else if (item.errorMsg === true && uncheckedRecord === false) {
        item.checkedAndNotFilledInvoice = true;
        return true;
      } else {
        item.checkedAndNotFilledInvoice = false;
        return false;
      }
    });
    return this.rejectedRecords.length;
  }

  updateInvoiceSource(selectedInvoice, index, unchecked?): any {
    const selectedInvoiceId = this.checkInvoices(selectedInvoice);
    this.listofSelectedInvoices.push(selectedInvoice.docNo);
    if (this.listofSelectedInvoices.length > 0) {
      InvoiceUtils.fetchdropDownSource(
        this.creditList,
        this.creditDropDownSource,
        this.listofSelectedInvoices
      );
    }
    const selectedInvoiceDetails = _.filter(
      this.creditDropDownSource,
      (item) => {
        const checkedNumber = this.checkInvoices(item);
        if (checkedNumber === selectedInvoiceId && item.docNo === selectedInvoice.docNo) {
          return true;
        } else {
          return false;
        }
      }
    );
    const value = selectedInvoiceDetails[0]?.amount;
    this.creditList[index].applyToInvoice =
      selectedInvoiceDetails[0]?.displayValue;
    this.creditList[index].invoiceamount = selectedInvoiceDetails[0]?.amount;
    this.creditList[index].invoicenumber = selectedInvoiceDetails[0]?.docNo;
    this.creditList[index].invoice_fisc_year = selectedInvoiceDetails[0]?.invoice_fisc_year;
    this.creditList[index].invoice_item_no = selectedInvoiceDetails[0]?.invoice_item_no;
    this.creditList[index].invoice_due_date = selectedInvoiceDetails[0]?.invoice_due_date;
    this.creditList[index].invoice_refDocNo = selectedInvoiceDetails[0]?.refDocNo;
    this.creditList[index].invoice_docType = selectedInvoiceDetails[0]?.invoice_docType;
    this.creditList[index].invoice_dochdrtxt = selectedInvoiceDetails[0]?.invoice_dochdrtxt;
    this.creditList[index].invoice_refhdr = selectedInvoiceDetails[0]?.invoice_refhdr;

    this.creditList[index].errorMsg = false;
    this.creditList[index].checkedAndNotFilledInvoice = false;

    if (this.creditList[index].previouseInvoice !== undefined && this.creditList[index].previouseInvoice.docNo !== selectedInvoice.docNo) {
      this.creditDropDownSource.forEach((item) => {
        if (item.docNo === this.creditList[index].previouseInvoice.docNo) {
          item.pendingBalance += Number(
            this.creditList[index].balanceToBeAdded
          );
          item.includeInList = false;
          InvoiceUtils.fetchdropDownSource(
            this.creditList,
            this.creditDropDownSource,
            this.listofSelectedInvoices
          );
        }
      });
      this.creditList[index].previouseInvoice = selectedInvoice;
    }

    // Checking Previous Values
    if (this.creditList[index].previouseInvoice === undefined) {
      this.creditList[index].previouseInvoice = selectedInvoice;
    }
    // Assign Credit Applied Value
    InvoiceUtils.computeInvoiceandCreditBalances(
      this.creditList,
      index,
      value,
      this.creditDropDownSource,
      selectedInvoiceId, selectedInvoice,
      unchecked
    );

    // tslint:disable-next-line: no-unused-expression
    this.creditList.length > 1
      ? (this.isValid = this.checkRejectedRecords(true) === 0)
      : null;
  }
  updatedInvoiceDropdown(selectedInvoice, index) {
    if (selectedInvoice !== undefined) {
      this.updateInvoiceSource(selectedInvoice, index);
      InvoiceUtils.fetchdropDownSource(
        this.creditList,
        this.creditDropDownSource,
        this.listofSelectedInvoices
      );
    }
  }
  checkInvoices(data) {
    let selectedData;
    if (data.invoice_docType === 'DZ') {
      selectedData = data.invoice_dochdrtxt;
    } else if (data.invoice_docType === 'YR') {
      selectedData = data.invoice_refhdr;
    } else {
      selectedData = data.refDocNo;
    }
    return selectedData;
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  dateComparison(dueDate): boolean {
    return CommonUtils.dateComparison(dueDate, this.myDate);
  }
  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.creditInvoiceDateSubscription?.unsubscribe();
    this.closedInvoiceDateSubscription?.unsubscribe();
    this.csAgentAuthSubscription?.unsubscribe();
    this.accountIdSubscription?.unsubscribe();
  }
}
