import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomInfluenzaService } from '../../../custom-influenza/custom-influenza.service';
import { siteLanguagesEng } from '../../../custom-user-registration/registration.constants';
import {
  createReservationEng,
  createReservationFr,
  viewReservationEng,
  viewReservationFr,
} from '../../account-overview.constants';

@Component({
  selector: 'app-flu-content',
  templateUrl: './flu-content.component.html',
  styleUrls: ['./flu-content.component.scss'],
})
export class FluContentComponent implements OnInit,OnDestroy {
  userData: any = [];
  activeSeasons: any;
  endDate: any;
  todayDate: Date;
  startDate: any;
  orderHistory: any = [];
  reservationId: any;
  compNameAdBanner6 = 'SanofiAccountOverviewBannerComponent6';
  fluResevartion: boolean;
  createBtnText = createReservationEng;
  viewBtnText = viewReservationEng;
  activeSeasonsSubscriber: Subscription;
  orderHistorySubscriber: Subscription;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: true,
  };
  userAccsubs: Subscription;
  displayActiveSeasons = true;
  slickInit(e): void {}
  breakpoint(e): void {}
  afterChange(e): void {}
  beforeChange(e): void {}
  constructor(
    private readonly user: UserAccountFacade,
    private readonly influenzaService: CustomInfluenzaService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly langService: LanguageService
  ) {}

  ngOnInit(): void {
    this.langService.getActive().subscribe((langResult) => {
      if (langResult) {
        if (langResult === siteLanguagesEng) {
          this.createBtnText = createReservationEng;
          this.viewBtnText = viewReservationEng;
        } else {
          this.createBtnText = createReservationFr;
          this.viewBtnText = viewReservationFr;
        }
        this.cdr.detectChanges();
      }
    });
    this.checkInfReservations();
  }

  checkInfReservations() {
    this.userAccsubs = this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
        this.influenzaService.getActiveSeasonsFromDb(this.userData.userName);
        this.activeSeasonsSubscriber =
          this.influenzaService.activeSeaons.subscribe((data) => {
            if (!!data) {
              this.activeSeasons = data.categoryData[0]?.code;
              this.todayDate = new Date();
              this.endDate = data.categoryData[0]?.endDate;
              this.startDate = data.categoryData[0]?.startDate;
              this.activeSeasonsSubscriber?.unsubscribe();
              this.influenzaService.getUserOrderHistory(
                this.userData.userName,
                this.activeSeasons
              );
            }
            if (this.todayDate > new Date(this.endDate) || this.todayDate < new Date(this.startDate) || !data.categoryData?.[0]) {
              this.displayActiveSeasons = false;
            }
          });

        this.orderHistorySubscriber =
          this.influenzaService.ordersHistory.subscribe((data) => {
            this.orderHistory = data?.orders;
            if (this.orderHistory) {
              this.reservationId = this.orderHistory?.[0]?.guid;
            }
            if (this.orderHistory?.length > 0) {
              this.fluResevartion = true;
            }
            this.orderHistorySubscriber?.unsubscribe();
            this.cdr.detectChanges();
          });
      }
    });
  }

  onInfClick() {
    if (this.fluResevartion) {
      this.influenzaService.getDetailedReservation(
        this.userData.userName,
        this.reservationId,
        true,
        false
      );
    } else {
      this.router.goByUrl('/influenza/dashboard');
    }
  }
  navigatetoDb() {
    this.router.goByUrl('/influenza/dashboard');
  }
  ngOnDestroy() {
    this.activeSeasonsSubscriber?.unsubscribe();
    this.orderHistorySubscriber?.unsubscribe();
    this.userAccsubs?.unsubscribe();
  }
}
