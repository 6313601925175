<section class="container footer-Container-Section">
  <section class="row">
    <section class=" col-12 col-sm-12 col-md-5 col-lg-3 col-xl-3 influenza-Contact-Section">
      <section class="row">
        <section class="col-8 col-sm-8 col-md-8 inf-Contact">{{'selectVaccines.influenzaContact'|cxTranslate}}</section>
      </section>
      <!--edit functionality was removed-->
      <section class="container" *ngIf="contactAddress">
        <section class="col-md-12 res-contact padd-0">
          <ul class="no-Bullets">
            <li>
              <p class="displayCell">{{'profile.name'|cxTranslate}}</p>
              <span class="align-Top displayCell" *ngIf="contactAddress?.firstName">{{contactAddress?.firstName |
                titlecase}} {{contactAddress?.lastName ?
                (contactAddress?.lastName
                | titlecase) : ''}} </span>
            </li>
            <li>
              <p>{{'selectVaccines.emailUser'|cxTranslate}}</p>
              <a href="{{'mailto:'+contactAddress?.email}}" class="align-Top prefer-Value">{{contactAddress.email}}</a>
            </li>
          </ul>
        </section>
      </section>
    </section>
    <!--middlesec-->
    <section class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 active-season-Section">
      <section *ngIf="displayActiveSeasons">
        <section class="row">
          <section class="col-12 col-xs-12 col-md-8 col-xl-9 col-lg-9" >
            <section class="season-header" *ngIf="activeSeasons">
              {{'selectVaccines.season'|cxTranslate}} {{activeSeasons}}
            </section>
          </section>
          <section class=" col-12 col-xs-12 col-md-4 col-xl-3 col-lg-3" *ngIf="dispalayFooterSeason && activeSeasons">
            <button class="btn Reserve-Btn text-button-initial"
              (click)="onReserve()">{{'selectVaccines.reserve_now'|cxTranslate}}</button>
          </section>
        </section>
        <!--products-->
        <section class="row row-Gap" *ngFor="let product of productList; let i = index" id="{{product.code}}">
          <section class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
            <a (click)="productDetails(product)">
              <img *ngIf="product.thumbNailImg !== ''" src="{{baseUrl}}{{product.thumbNailImg}}" class="vaccine-Image"
                alt="prodImg" />
              <img *ngIf="product.thumbNailImg == ''" [src]="fallbackImage" class="vaccine-Image" alt="defImg" />
            </a>
          </section>
          <section class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6 prod-Desc-Div">
            <section class="container">
              <section class="row">

                <section class="col-md-12 col-lg-12 col-xl-12">
                  <a (click)="productDetails(product)" title="{{product.name}}" class="vaccine-Name"
                    [innerHTML]="product.name"></a>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12 vaccine-Desc-Section" [innerHTML]="product.description">
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12 prod-No" *ngIf="!hospital && product?.cipCode !== undefined">
                  {{'selectVaccines.cipCode'|cxTranslate}}:
                  <span class="prodct-No">{{product?.cipCode}}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12 prod-No" *ngIf="hospital && product?.ucdCode !== undefined">
                  {{'selectVaccines.ucdCode'|cxTranslate}}:
                  <span class="prodct-No">{{product?.ucdCode}}</span>
                </section>
              </section>
            </section>
          </section>
          <!-- ============================order acceptance=========================================== -->

          <section class="col-5 col-sm-5 col-md-3 col-xs-6 order-Acceptance">
            <span *ngIf="!product.available && product.discontinued===false" class="">
              {{product?.availabilityText ? product?.availabilityText :
              ('selectVaccines.product_unavailable'|cxTranslate) }}
            </span>
            <span class="" *ngIf="product.discontinued===true">
              {{'selectVaccines.product_discontinued'|cxTranslate}}</span>
            <span class="available-now"
              *ngIf="product.onsn === 'ON' && product.discontinued===false && product.available===true">
              {{'selectVaccines.available_now'|cxTranslate}}
            </span>
          </section>
        </section>
      </section>
    </section>
    <!-- <section class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 ad-Banner-Section">
      <app-flu-dashboard-banner [bannerComponent]="compName"></app-flu-dashboard-banner>
    </section> -->
  </section>
</section>
