import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  ProductReferenceService,
  ProductService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { AccountType } from "../../custom-user-registration/registration.constants";
import { CustomPdpService } from '../custom-pdp.service';
@Component({
  selector: 'app-custom-pdp',
  templateUrl: './custom-pdp.component.html',
  styleUrls: ['./custom-pdp.component.scss'],
})
export class CustomPdpComponent implements OnInit, OnDestroy {
  initialQuantity: number;
  sessionStorage = sessionStorage;
  productCode: string;
  productDetails: any;
  monographLink: any;
  monograph: any;
  iscarousel = false;
  buttonName = 'Login to order';
  panelVolumeExpanded = false;
  panelProductExpanded = false;
  userDetails: any;
  relatedProducts = [];
  relatedProductsFormatted: any;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  description: any;
  cartId: any;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    arrows: true,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  displaySection = false;
  itemAddedQuantity: any;
  cartItems: any;
  slickInit(e): void {}
  breakpoint(e): void {}
  afterChange(e): void {}
  beforeChange(e): void {}
  cartDetailsSub: Subscription;
  userSub: Subscription;
  cartItemsSub: Subscription;
  productCodeSub: Subscription;
  createdCartSub: Subscription;
  relatedProdSub: Subscription;
  wrongQuantity: boolean = false;
  hospital: boolean;
  boxesQuantity;
  activeFluSeason: boolean;
  userInput: string = '';
  roundedValue: number | null = null;
  dividedValue: number | null = null;

  constructor(
    private readonly productSearch: ProductService,
    private readonly route: ActivatedRoute,
    private readonly routerservice: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly pdpService: CustomPdpService,
    private readonly referenceService: ProductReferenceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly routingService: RoutingService,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
    private readonly dataLayerService: DataLayerService,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    this.activeFluSeason = JSON.parse(sessionStorage.getItem('activeFluSeason'));
    this.route.params.forEach((params: Params) => {
      this.populatePDP();
    });
  }

  populatePDP() {
    this.productDetails = [];
    this.productCode = this.route.snapshot.params.productCode;
    this.pdpService.getPDPCategory(this.productCode);
    this.userSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.pdpService.getCarts(this.userDetails?.userName);
      }
    });
    this.cartItemsSub = this.pdpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
    this.productCodeSub = this.pdpService.productCode$.subscribe((res) => {
      if (res) {
        this.dataLayerService.viewItemDataLayer(res, 'standard process');
        this.displaySection = res.hasOwnProperty('code');
        this.productDetails = res;
        this.description = this.sanitizer.bypassSecurityTrustHtml(
          this.productDetails.description
        );
        this.monograph = this.productDetails?.productDocuments;
        if (this.monograph) {
          for (const link of this.monograph?.productDocument) {
            this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
              link.url
            );
          }
        }
        if (this.userDetails !== undefined) {
          // Set buttonName based on conditions
          if (!this.hospital && this.isIndirect()) {
            this.buttonName = 'Reserve now';
          } else if (this.hospital && this.isIndirect()) {
            this.buttonName = 'Add to cart';
          } else if (this.productDetails.flu === true && (!this.hospital || this.hospital && this.activeFluSeason)) {
            this.buttonName = 'Reserve now';
          } else {
            this.buttonName = 'Add to cart';
          }
        } else {
          this.buttonName = 'Login to order';
        }
         let stepQuantity = this.productDetails.stepQuantity;
            let minQuantity = this.productDetails.minQuantity;
            if(this.productDetails.stepQuantity){
              this.initialQuantity = this.productDetails.stepQuantity
              let calculatedQty = Math.ceil(this.productDetails.stepQuantity / this.productDetails.numberOfDosesPerUnit);
              this.boxesQuantity = calculatedQty === 0 ? 1 : calculatedQty
            }else{
              if (stepQuantity == null) {
                this.initialQuantity = minQuantity;
              } else if (stepQuantity >= minQuantity) {
                this.initialQuantity = stepQuantity;
              } else {
                let multiplier = Math.ceil(minQuantity / stepQuantity);
                this.initialQuantity = stepQuantity * multiplier;
              }
            }
      }
    });
  }
  addToCart(productCode, popover): void {
    if (this.buttonName === 'Reserve now') {
      if (this.isIndirect() && !this.hospital) {
        this.navigateToIndirectCatalog();
      }
      else {
        this.navigateToFlu();
      }
    } else if (this.buttonName === 'Add to cart' || this.hospital) {
      const inputElement = document.getElementById(
        `prodQuantity${productCode}`
      ) as HTMLInputElement;

      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement.value,
      };
      this.itemAddedQuantity = inputElement.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.pdpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.pdpService.getCarts(this.userDetails?.userName);
            this.UpdateCartDetails(params, popover);
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems =
            this.cartItems && this.cartItems?.length > 0
              ? this.cartItems[this.cartItems?.length - 1]
              : [];
          this.cartId = this.cartItems?.code;
        }
        this.UpdateCartDetails(params, popover);
      }
    }
  }
  UpdateCartDetails(params, popover): void {
    this.pdpService.updateCart(
      params,
      this.userDetails?.userName,
      this.cartId,
      params.quantity
    );
    this.cartDetailsSub = this.pdpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          popover.toggle();
          setTimeout(() => popover.close(), 2500);
          this.cdr.detectChanges();
        }
      }
    );
  }
  performQuantityComputation(
    product,
    operator: number | null, // Added explicit typing
      idData: string,           // Added explicit typing
      stepQuantity: number,     // Added explicit typing
      minQuantity: number,      // Added explicit typing
      maxQuantity: number
  ):
  void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;

    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart',
        product.numberOfDosesPerUnit
      );
           const inputElement = document.getElementById(`prodQuantity${product.code}`) as HTMLInputElement;
               this.userInput = inputElement.value; // Update userInput

               // Always call the rounding logic after updating the quantity
               this.roundToNearestMultipleOfStepQuantity(stepQuantity);
               this.cdr.detectChanges();

    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }

  isIndirect(): boolean {
    return this.productDetails?.productType &&
      this.productDetails?.productType === 'INDIRECT';
  }

  navigateToIndirectCatalog(): void {
    this.routingService.goByUrl('/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }

  goToLogin() {
    this.routingService.goByUrl('/login');
  }
  openImage(content) {
    this.modalService.open(content, {
      windowClass: 'carousel-modal',
    });
  }
  navigateToFlu() {
    this.routerservice.goByUrl('/influenza/dashboard');
  }
  ngOnDestroy() {
    this.pdpService.productCode.next(null);
    this.displaySection = false;
    this.cartDetailsSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.productCodeSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.relatedProdSub?.unsubscribe();
  }

  // Quantité UCD round to nearest multiple of 6

    roundToNearestMultipleOfStepQuantity(stepQuantity: number) {
      const parsedInput = parseFloat(this.userInput);
      if (!isNaN(parsedInput) && parsedInput > 0) {
        if (parsedInput % stepQuantity !== 0) {
          this.roundedValue = Math.ceil(parsedInput / stepQuantity) * stepQuantity;
        } else {
          this.roundedValue = parsedInput;
        }

        if (this.roundedValue !== null) {
          this.userInput = this.roundedValue.toString();
          this.dividedValue = this.roundedValue / stepQuantity;
        }

        // Enable/disable the minus button based on the current value
        this.updateMinusButtonState();
      } else {
        // Handle invalid input
        this.roundedValue = null;
        this.dividedValue = null;
      }
      this.cdr.detectChanges();
    }

  updateMinusButtonState() {
    const inputElement = document.getElementById(`prodQuantity${this.productDetails.code}`) as HTMLInputElement;
    const currentQuantity = parseInt(inputElement.value, 10);
    const minusButton = document.getElementById(`unitMinus${this.productDetails.code}`);
    const minusButtonDisabled = document.getElementById(`unitMinusDisable${this.productDetails.code}`);

    if (currentQuantity > this.productDetails.minQuantity) {
      minusButton?.classList.remove('disabled-img');
      minusButtonDisabled?.classList.add('disabled-img');
    } else {
      minusButton?.classList.add('disabled-img');
      minusButtonDisabled?.classList.remove('disabled-img');
    }
  }
}
