<cx-searchbox [quickOrder]='true' [searchValueClear]="clearSearchValue"
  (selectedProductEvent)="onProductSelected($event)" [config]='QUICKORDER_SEARCH_BOX_CONFIG' [additionalQuery]="this.addQuery"></cx-searchbox>

<section *ngIf="quickOrderDetail" class="quick-prod-details">
<section *ngIf="(!productInfo?.flu || hospital && !activeFluSeason) && !productInfo?.discontinued" class="row unit-calc">
  <section class="price-details">
      <b *ngIf="!hospital" class="margin-bottom-20">{{'plp.yourPrice'|cxTranslate}}</b>
      <b *ngIf="hospital" class="margin-bottom-20">{{'plp.ucdPrice'|cxTranslate}}</b>
      <section class="price-total">
        <span *ngIf="productInfo?.price == undefined || productInfo?.price?.value === undefined">-</span>
        <span *ngIf="productInfo?.price && productInfo?.price?.value !== undefined">
          <span>{{productInfo?.price?.value | localCurrency}}</span>
        </span>
      </section>
    </section>

    <section class="no-price"
      *ngIf=" productInfo?.price == null || productInfo?.price == undefined || (productInfo?.productType != 'BEYFORTUS' && productInfo?.price?.value == 0.0 )">
      <p>{{'plp.noPrice' | cxTranslate}}</p>
    </section>

    <section class="units"
      *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value != undefined && ( productInfo?.productType == 'BEYFORTUS' || productInfo?.price?.value != 0.0)) && (!productInfo?.discontinued && productInfo?.available)">

      <b class="prodUnits">{{ hospital ? ('plp.hospitalUnits' | cxTranslate) : ('plp.units' | cxTranslate) }}
      </b>
      <section class="unit-area">
        <span class="disabled " aria-hidden="true" id="{{'minusIcon'+productInfo.code+'q'}}">
          <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productInfo.code+'q'}}" class="disabled-img"
            alt="reduce-quantity" (click)="performQuantityComputation(
                          productInfo,
                          2,
                          productInfo.code+'q',
                          productInfo.stepQuantity,
                          productInfo.minQuantity,
                          productInfo.maxQuantity
                          )">
          <img src="../../assets/icons/less.svg" id="{{'unitMinusDisable'+productInfo.code+'q'}}" alt="">
        </span>
        <input type="number" *ngIf="!productInfo.numberOfDosesPerUnit; else numberOfBoxesInput" class="prod-quantity text-center" value={{productInfo.stepQuantity}} (keyup)="performQuantityComputation( productInfo,
          null,
          productInfo.code+'q',
          productInfo.stepQuantity,
          productInfo.minQuantity,
          productInfo.maxQuantity)" onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
          id="{{'prodQuantity'+productInfo.code+'q'}}" maxlength="4" />

          <ng-template #numberOfBoxesInput >
            <input
              id="numberInput"
              type="number"
              class="prod-quantity text-center"
              [value]="roundedValue ? roundedValue : productInfo.numberOfDosesPerUnit"
              (input)="userInput = $event.target.value"
              (blur)="roundToNearestMultipleOfStepQuantity(productInfo.stepQuantity)"
              id="{{'prodQuantity'+productInfo?.code+'q'}}" min="1" max="9999"
            />
          </ng-template>

        <span class="" aria-hidden="true" id="{{'plusIcon'+productInfo.code+'q'}}">
          <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productInfo.code+'q'}}" alt="increase-quantity"
            (click)="performQuantityComputation(
            productInfo,
            1,
            productInfo.code+'q',
            productInfo.stepQuantity,
            productInfo.minQuantity,
            productInfo.maxQuantity
            )">
          <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+productInfo.code+'q'}}"
            class="disabled-img" alt="">
        </span>
      </section>

      <div *ngIf="productInfo.numberOfDosesPerUnit" class="doses-section" id="{{'noOfDoses'+productInfo?.code+'q'}}">
                <span *ngIf="dividedValue < 1; else dividedValueNumber">
                  {{ productInfo.numberOfDosesPerUnit / productInfo.numberOfDosesPerUnit }} {{'plp.boxes'|cxTranslate}}
                </span>
        <ng-template #dividedValueNumber>
          {{ dividedValue }} {{'plp.boxes'|cxTranslate}}
        </ng-template>
      </div>

    </section>
    <section *ngIf="!productInfo?.discontinued" class="col-md-12 col-lg-12 col-xl-12 vol-Pricing mb-3">
      <p class="product-discontinued-txt1" *ngIf="productInfo?.messageDangerousGoods">
        {{'plp.messageDangerousGoods'|cxTranslate}}</p>
      <p class="product-discontinued-txt1" *ngIf="productInfo?.messageColdChain">{{'plp.messageColdChain'|cxTranslate}}
      </p>
    </section>
    <button type="button" class="btn blue-button quickorder-button"
      *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value !== undefined && (productInfo?.productType == 'BEYFORTUS' || productInfo?.price?.value !== 0.0)) && (!productInfo?.discontinued && productInfo?.available) && (hospital || (!hospital && productInfo?.productType != 'INDIRECT'))"
      [disabled]="wrongQuantity" [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover"
      popoverClass="savesuccess-msg" [closeDelay]="500" (click)="addToCart(productInfo?.code+'q',productInfo?.code)">

      <ng-template #popTitleSuccess>
        <div class="cart-value-added">
          <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon" alt="suc">
          <span class="confirmation-itemsadded">{{itemAddedQuantity}}
            {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
        </div>
      </ng-template>
      <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
      {{buttonName}}
    </button>
    <section
      *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value != undefined && ( productInfo?.productType == 'BEYFORTUS' || productInfo?.price?.value != 0.0)) && (!productInfo?.discontinued && productInfo?.available)">
      <section *ngIf="productInfo.sapAllocation">
        <b class="prod-allocation">{{'plp.productAllocation' | cxTranslate}}</b>
        <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
      </section>
    </section>
    <section class="col-md-12 col-lg-12 col-xl-12 vol-Pricing mb-3" *ngIf="productInfo?.available">
      <a class="link vol-Price" placement="top" *ngIf="productInfo?.volumePrices?.length > 0" [ngbPopover]="popContent"
        popoverTitle="{{'plp.volumePricing'|cxTranslate}}">
        {{'plp.volumePricing'|cxTranslate}}
      </a>
      <ng-template #popContent>
        <table class="tb" id="vol-price-pdp" aria-describedby="volTable">
          <thead>
            <th scope="col"></th>
          </thead>
          <tbody *ngFor="let prices of productInfo?.volumePrices; let i=index">
            <tr>
              <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                {{'selectVaccines.units'|cxTranslate}}:
              </td>
              <td>{{prices?.value | localCurrency}}</td>
              <td><span *ngIf="i>0" class="save-price">{{'selectVaccines.save'|cxTranslate}}
                  {{productInfo?.volumePrices[0].value - productInfo?.volumePrices[i].value | localCurrency}}
                  (
                  {{(productInfo?.volumePrices[0].value - productInfo?.volumePrices[i].value) /
                  productInfo?.volumePrices[0].value | percent:'1.2-2'}}
                  )
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </section>
  </section>

  <section *ngIf="productInfo?.discontinued || !productInfo?.available">
    <section *ngIf="productInfo?.discontinued && productInfo?.available">
      <p class="product-discontinued-txt1">
        {{'plp.discontinued' | cxTranslate}}</p>
    </section>
    <section *ngIf="productInfo?.discontinued && !productInfo?.available">
      <p class="product-discontinued-txt1">
        {{'plp.discontinued' | cxTranslate}}</p>
    </section>
    <section *ngIf="!productInfo.discontinued && !productInfo?.available" class="m0 left-align">
      <b class="prod-allocation class-avail" *ngIf="productInfo?.availabilityText">{{productInfo?.availabilityText}}</b>
      <b class="prod-allocation class-avail" *ngIf="!productInfo?.availabilityText">{{'plp.noavailabilityText' |
        cxTranslate}}</b>
    </section>
  </section>

  <section *ngIf="productInfo?.flu && (!hospital || hospital && activeFluSeason)" class="flu-section">
    <section>
      <button type="button" class="btn blue-button m0-spacing"
        *ngIf="productInfo.available && !productInfo.discontinued" (click)="navToReservenow()">
        <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
        {{'plp.reserve'|cxTranslate}}</button>
    </section>
  </section>

  <section *ngIf="(!hospital && productInfo?.productType == 'INDIRECT')" class="flu-section">
    <section>
      <button type="button" class="btn btn-blue-background m0-spacing"
        *ngIf="productInfo.available && !productInfo.discontinued" (click)="navToIndirectPLP()">
        <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
        {{'plp.placeMyOrder'|cxTranslate}}</button>
    </section>
  </section>

</section>
