import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekNumber'
})
export class WeekNumberPipe implements PipeTransform {

  transform(value: string): number {
    if (!value) {
      return 0;
    }
    const [day, month, year] = value.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
}
