<div class="col-xs-12 col-sm-12 col-md-12 Registration-split pb-4">
  <h3 class="pdp-title">{{ "userRegistration.accountInformation.header" | cxTranslate }}</h3>
  <span class="title-sub-notify">{{"userRegistration.accountInformation.title" | cxTranslate}}</span>
  <span class="title-sub-heading">{{"userRegistration.accountInformation.formTitle" | cxTranslate}}</span>
  <div class="accountNotExist alert alert-danger alert-dismissable getAccAlert"
    [ngClass]="{ 'acc-invalid': billToShipError === true }" *ngIf="errorMsg">
    <span class="dismissible-error-message"
      *ngIf="!billToShipError && !isEmailExists && !accountInfoForm.get('registeredAkamaiUser').value && !accountIsDeleted">
      {{"userRegistration.accountInformation.formValidationMsg" | cxTranslate}}
    </span>
    <span class="dismissible-error-message"
          *ngIf="!billToShipError && !isEmailExists && !accountInfoForm.get('registeredAkamaiUser').value && accountIsDeleted">
      {{"userRegistration.accountInformation.messageDeactivationB2b" | cxTranslate}}
    </span>
    <span class="dismissible-error-message"
      *ngIf="billToShipError && !isEmailExists && !accountInfoForm.get('registeredAkamaiUser').value">
      {{"userRegistration.accountInformation.billToShipToValidationMsg"| cxTranslate}}
    </span>
    <span class="dismissible-error-message" *ngIf="isEmailExists">
      {{"userRegistration.accountInformation.emailExistMsg"
      | cxTranslate }}</span>
    <span class="dismissible-error-message" *ngIf="invalidPostCodeError">
      {{"errors.invalidPostCode"
      | cxTranslate }}</span>
    <span class="dismissible-error-message" *ngIf="akamaiPasswordValidationError">
      {{"userRegistration.accountInformation.akamaiPasswordValidationMsg"|
      cxTranslate}}</span>
  </div>
  <div class="alert alert-info alert-dismissable getAccAlert"
    [ngClass]="{ 'acc-invalid': billToShipError === true }" *ngIf="isPasswordEmptyInAkamai">
    <span class="dismissible-error-message">
      {{ "userRegistration.accountInformation.passwordIsEmptyInAkamai" |
      cxTranslate }}</span>
  </div>
  <form [formGroup]="accountInfoForm" id="accountInfoForm" (ngSubmit)="validateAccountInfo()">
    <div class="col-xs-12 col-sm-8 col-md-7 p0">
      <div id="registerBilling">
        <div class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
            zip-container
          ">
          <ng-template #accountNumberPopContent>{{"userRegistration.accountInformation.accountNumber.popoverMsg" |
            cxTranslate}}</ng-template>
          <label for="accountNumber" class="Fields-label icon-label">{{
            "userRegistration.accountInformation.accountNumber.label"
            | cxTranslate
            }}
          </label>
          <a placement="top" [ngbPopover]="accountNumberPopContent"><img src="../../assets/icons/faqs_reg.png"
              alt="faq_reg" class="faq" /></a>
          
            <input id="accountNumber" name="accountNumber" data-valid="valid_facility"
            aria-labelledby="accountNumber tooltipFacilityName" type="text" placeholder="XXXXXXXXXXXXXX"
            class="Fields-input" autocomplete="off" formControlName="accountNumber" data-di-id="#accountNumber"
            [attr.readonly]="isIndirectB2BUnitValidated && selectedRoleHcp == true ? '' : null" [(ngModel)]="inputValue"
            (input)="checkLength()" 
            (ngModelChange)="checkLength()"
            [ngClass]="invalidSiretNumberError ? 'siretNumberError' : '' "/>

          <span *ngIf="
              !accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            " class="errorMessage">{{
            "userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidation"
            | cxTranslate
            }}</span>
          <span *ngIf="
              accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
          <span *ngIf="
            invalidSiretNumberError && 
            accountInfoForm.get('accountNumber').valid &&
            accountInfoForm.get('accountNumber').touched" 
            class="error defaultMessage mb-4">
            {{
              "userRegistration.accountInformation.accountNumber.validationMessage.acceptsCorrectSIRETNumber"
              | cxTranslate
              }}
            </span>
        </div>
        
       <div *ngIf="accTypeRegistration">
        <div class="
            col-xs-12 col-sm-12 col-md-12
            pb-2
            p0
            Fields-container
            doubleLineContainer
            zip-container
          ">
          <ng-template #accountAliasNumberPopContent>{{
            "userRegistration.accountInformation.accountAlias.popoverMsg"
            | cxTranslate
            }}</ng-template>
          <label for="accountAlias" class="Fields-label icon-label">{{
            "userRegistration.accountInformation.accountAlias.label"
            | cxTranslate
            }}
          </label>
          <a placement="top" [ngbPopover]="accountAliasNumberPopContent"><img src="../../assets/icons/faqs_reg.png"
              alt="faq_reg" class="faq" /></a>
          <input id="accountAlias" name="accountAlias" data-valid="valid_facility"
            aria-labelledby="accountAlias tooltipFacilityName" type="text" placeholder="XXXXXXXXX" class="Fields-input"
            autocomplete="off" formControlName="accountAlias" data-di-id="#accountAlias"
            [attr.readonly]="isIndirectB2BUnitValidated && selectedRoleHcp == true ? '' : null" />

          <span *ngIf="
              !accountInfoForm.get('accountAlias').errors?.required &&
              !accountInfoForm.get('accountAlias').valid &&
              accountInfoForm.get('accountAlias').touched
            " class="errorMessage">{{
            "userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidation"
            | cxTranslate
            }}</span>
          <span *ngIf="
              accountInfoForm.get('accountAlias').errors?.required &&
              !accountInfoForm.get('accountAlias').valid &&
              accountInfoForm.get('accountAlias').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
        <div class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            zip-container
          ">
          <label for="postalCode" class="Fields-label">{{
            "userRegistration.accountInformation.postalCode.label" | cxTranslate
            }}</label>
          <input id="postalCode" name="postalCode" data-valid="valid_zip_canada" type="text" maxlength="5"
            class="Fields-input" autocomplete="off" formControlName="postalCode" data-di-id="#postalCode"
            [attr.readonly]="isIndirectB2BUnitValidated && selectedRoleHcp == true && invalidPostCodeError == false? '' : null"
            placeholder="XXXXX" />
          <span *ngIf="
              accountInfoForm.get('postalCode').errors?.pattern &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            " class="errorMessage" role="alert" aria-live="assertive">{{
            "userRegistration.accountInformation.postalCode.invalidFormatMsg"
            | cxTranslate
            }}</span>
          <span *ngIf="
              accountInfoForm.get('postalCode').errors?.required &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 zip-container Fields-container" id="accountTypeCheckBoxes">
          <label for="roleSelect" class="Fields-label">{{"userRegistration.accountInformation.chooseRole" |
            cxTranslate}}</label>
          <form [formGroup]="accountInfoForm">
            <div class="role-type-container">
              <select class="role-type" id="roleSelect" formControlName="rolesFlag" (change)="changeRole()">
                <option value="default" disabled>{{"userRegistration.accountInformation.chooseRole" | cxTranslate}}
                </option>
                <option *ngFor="let role of filteredRoles" [ngValue]="role" class="roleName">{{role.name}}</option>
              </select>
              <input type="text" formControlName="wholeSalerFlag" hidden>
              <input type="text" formControlName="role" hidden>
              <div class="role-type-arrow"></div>
            </div>
          </form>
          <span *ngIf="accountInfoForm.get('rolesFlag').value === 'default' && accountInfoForm.get('rolesFlag').touched"
            class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
      
      </div>
      </div>
    </div>
    <div *ngIf="accTypeRegistration">
      <div class="col-xs-12 col-sm-8 col-md-7 p0" *ngIf="selectedRoleHcp == true">
        <div formGroupName="emailInfo">
          <div class="col-xs-12 col-sm-6 col-md-7 p0 Fields-container">
            <label for="userEmail" class="Fields-label">{{
              "userRegistration.userInformation.email.label" | cxTranslate
              }}
            </label>
            <input id="userEmail" name="email" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="newemail" formControlName="email" data-di-id="#userEmail"
              [attr.readonly]="isIndirectB2BUnitValidated || skipAkamaiValidation ? '' : null" />

            <span *ngIf="
                  isEmailExists && !accountInfoForm.get('emailInfo.email').touched
                " class="errorMessage">
              {{
              "userRegistration.userInformation.email.existingEmail"
              | cxTranslate
              }}</span>

            <span *ngIf="
                  !!accountInfoForm.get('emailInfo.email').value &&
                  accountInfoForm.get('emailInfo.email').invalid &&
                  accountInfoForm.get('emailInfo.email').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>
            <span *ngIf="
                  accountInfoForm.get('emailInfo.email').errors?.required &&
                  !accountInfoForm.get('emailInfo.email').valid &&
                  accountInfoForm.get('emailInfo.email').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
          <div *ngIf="isIndirectB2BUnitValidated && !accountInfoForm.get('registeredAkamaiUser').value"
            class="col-xs-12 col-sm-6 col-md-7 p0 Fields-container mt-2">
            <label for="userConfirmEmail" class="Fields-label">{{
              "userRegistration.userInformation.confirmEmail.label"
              | cxTranslate
              }}
            </label>
            <input id="userConfirmEmail" name="confirmEmail" data-valid="valid_mail" type="text" class="Fields-input"
              data-view="confirmemail" formControlName="confirmEmail" data-di-id="#userConfirmEmail" />

            <span *ngIf="
                  !!accountInfoForm.get('emailInfo.confirmEmail').value &&
                  accountInfoForm.get('emailInfo.confirmEmail').invalid &&
                  accountInfoForm.get('emailInfo.confirmEmail').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
              }}</span>

            <span *ngIf="
                  !!accountInfoForm.get('emailInfo.confirmEmail').value &&
                  !accountInfoForm.get('emailInfo.confirmEmail').invalid &&
                  accountInfoForm.get('emailInfo').errors?.emailNotMatch
                " class="errorMessage">{{
              "userRegistration.userInformation.confirmEmail.emailNotMatch"
              | cxTranslate
              }}</span>
            <span *ngIf="
                  accountInfoForm.get('emailInfo.confirmEmail').errors?.required &&
                  !accountInfoForm.get('emailInfo.confirmEmail').valid &&
                  accountInfoForm.get('emailInfo.confirmEmail').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
        </div>

        <div *ngIf="isIndirectB2BUnitValidated || userInfoDetailsGroup.get('password').value" class="
                col-xs-12 col-sm-12 col-md-12
                p0
                Fields-container
                zip-container
              " formGroupName="userInfoDetails">

            <label for="userPassword" class="Fields-label col-xs-12 col-sm-8 col-md-7 p0">
              {{ "userRegistration.userInformation.password.label"| cxTranslate}}
            </label>

            <input id="userPassword" name="password" data-valid="valid_facility"
            aria-labelledby="password tooltipFacilityName" type="password" class="Fields-input" autocomplete="off"
            formControlName="password" data-di-id="#userPassword"
            [attr.readonly]="this.userAuthDetails?.password ? '' : null" />

            <span class="Fields-label-basic col-xs-12 col-sm-8 col-md-7 p0"
                  *ngIf="accountInfoForm.get('registeredAkamaiUser').value">
              {{ "userRegistration.userInformation.password.foundHcp"| cxTranslate}}
              <br/>
              <span class="Fields-label-bold">
                {{"userRegistration.accountInformation.akamaiPasswordResetMessageFirstPartBold" |cxTranslate  }} </span>{{"userRegistration.accountInformation.akamaiPasswordResetMessageFirstPart" |cxTranslate}} <cx-generic-link  class="reset-link-akamai"
                                [url]="akamaiResetUrl">{{"userRegistration.accountInformation.akamaiPasswordResetLink" |cxTranslate}}</cx-generic-link>
              {{"userRegistration.accountInformation.akamaiPasswordResetMessageSecondPart" |cxTranslate}}
            </span>

          <span *ngIf="
                  !accountInfoForm.get('userInfoDetails.password').valid &&
                  !accountInfoForm.get('userInfoDetails.password').errors
                    ?.required  &&
                  accountInfoForm.get('userInfoDetails.password').touched
                " class="errorMessage">{{
            "userRegistration.userInformation.password.inValidPassword"
            | cxTranslate
            }}</span>
          <span *ngIf="
                  accountInfoForm.get('userInfoDetails.password').errors?.required &&
                  !accountInfoForm.get('userInfoDetails.password').valid &&
                  accountInfoForm.get('userInfoDetails.password').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>

        <div *ngIf="isIndirectB2BUnitValidated && !accountInfoForm.get('registeredAkamaiUser').value" class="
                col-xs-12 col-sm-12 col-md-12
                p0
                Fields-container
                zip-container
              " formGroupName="userInfoDetails">
          <label for="userConfirmPassword" class="Fields-label">{{
            "userRegistration.userInformation.confirmPassword.label"
            | cxTranslate
            }}</label>
          <input id="userConfirmPassword" name="confirmPassword" data-valid="valid_zip_canada" type="password"
            class="Fields-input" autocomplete="off" formControlName="confirmPassword" data-di-id="#userConfirmPassword" />

          <span *ngIf="
                  !accountInfoForm.get('userInfoDetails.confirmPassword').valid &&
                  !accountInfoForm.get('userInfoDetails.confirmPassword').errors
                    ?.required &&
                  accountInfoForm.get('userInfoDetails.confirmPassword').errors
                    ?.isPasswordInvalid &&
                  accountInfoForm.get('userInfoDetails.confirmPassword').touched
                " class="errorMessage">{{
            "userRegistration.userInformation.password.inValidPassword"
            | cxTranslate
            }}</span>

          <span *ngIf="
                  !!accountInfoForm.get('userInfoDetails.confirmPassword').value &&
                  accountInfoForm.get('userInfoDetails.confirmPassword').touched &&
                  !accountInfoForm.get('userInfoDetails.confirmPassword').errors
                    ?.isPasswordInvalid &&
                  accountInfoForm.get('userInfoDetails').errors?.mismatch
                " class="errorMessage">{{
            "userRegistration.userInformation.notMatchMsg" | cxTranslate
            }}</span>
          <span *ngIf="
                  accountInfoForm.get('userInfoDetails.confirmPassword').errors
                    ?.required &&
                  !accountInfoForm.get('userInfoDetails.confirmPassword').valid &&
                  accountInfoForm.get('userInfoDetails.confirmPassword').touched
                " class="defaultMessage" role="alert" aria-live="assertive">{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
      </div>
    </div>
    
    <div class="col-md-4" *ngIf="isIndirectB2BUnitValidated && !accountInfoForm.get('registeredAkamaiUser').value">
      <div class="password-security focusnone" id="passwordDesc" tabindex="-1">
        <app-security-information [formDetails]="false"></app-security-information>
      </div>
    </div>
   
    <div *ngIf="accTypeRegistration">
    <hr class="Fields-container-line billingPage" />
    <div class="col-xs-12 col-sm-12 col-md-12 captcha-x">
      <re-captcha formControlName="recaptchaReactive" (resolved)="resolved($event)"></re-captcha>
      <span *ngIf="
          accountInfoForm.get('recaptchaReactive').errors?.required &&
          !accountInfoForm.get('recaptchaReactive').valid &&
          accountInfoForm.get('recaptchaReactive').touched
        " class="defaultMessage" role="alert" aria-live="assertive">{{ "userRegistration.userInformation.requiredMsg" |
        cxTranslate }}
      </span>
    </div>
    <div class="invoice-btngrps">
      <p style="font-size: 12px; color: red" *ngIf="accountTypeError && !accountInfoForm.get('wholeSalerFlag').value">
        {{"userRegistration.accountInformation.accountTypeErrorMsg" | cxTranslate}}
      </p>
      <button type="submit" class="btn btn-default oi-blue-button reviewvalidbtn" data-di-id="di-id-7a6cb2cd-cb37948a">
        {{ "userRegistration.accountInformation.buttonLabel" | cxTranslate }}
      </button>
    </div>
  </div>
  </form>
</div>
