import {
  Component,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import {
  SearchBoxComponentService,
  SearchBoxConfig
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { BaseService } from 'src/app/shared/services/base.service';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { CustomAddToCartService } from '../../../../custom-add-to-cart/custom-add-to-cart.service';
import { CustomProdPgService } from '../../../../custom-prod-pg/custom-prod-pg.service';
import { AccountType } from "../../../../custom-user-registration/registration.constants";

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent implements OnInit, OnDestroy {
  @Output() selectedProductEvent = new EventEmitter<any>();
  quickOrderDetail = false;
  clearSearch = false;
  productInfo: any;
  buttonName: string;
  itemAddedQuantity: any;
  userDetails: any;
  cartId: any;
  cartItems: any;
  addQuery: string;
  QUICKORDER_SEARCH_BOX_CONFIG: SearchBoxConfig = {
    minCharactersBeforeRequest: 3,
    displayProducts: true,
    displaySuggestions: false,

    displayProductImages: true,
  };
  clearSearchValue = false;
  userDataSub: Subscription;
  allCartsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartSub: Subscription;
  updatedCartDetailsSub: Subscription;
  @ViewChild('p2') public successPopover: NgbPopover;
  wrongQuantity: boolean = false;
  hospital: boolean;
  numberOfDosesPerUnit: number;
  boxesQuantity = 0;
  activeFluSeason: boolean;
  userInput: string = '';
  roundedValue: number | null = null;
  dividedValue: number | null = null;

  constructor(
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly plpService: CustomProdPgService,
    protected searchBoxComponentService: SearchBoxComponentService,
    private readonly cdr: ChangeDetectorRef,
    private readonly addToCartService: CustomAddToCartService,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService,
    private dataLayerService: DataLayerService
  ) {}

  @Input('additionalQuery')
  set additionalQuery(addQuery: string) {
    this.addQuery = addQuery;
  }

  ngOnInit(): void {


    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    this.activeFluSeason = JSON.parse(sessionStorage.getItem('activeFluSeason'));
    if (this.productInfo?.flu === true) {
      this.buttonName = 'Passer ma commande';
    } else {
      this.buttonName = 'Ajouter au panier';
    }
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (res !== undefined) {
        if (this.productInfo?.flu === true && (!this.hospital || this.hospital && this.activeFluSeason)) {
          this.buttonName = 'Passer ma commande';
        } else {
          this.buttonName = 'Ajouter au panier';
        }
      } else {
        this.buttonName = 'Login to order';
      }
    });
    this.allCartsSub = this.addToCartService.allCartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems?.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
  }

  addToCart(idData, productCode): void {
  this.clearSearchValue = false;
  if (this.buttonName === 'Passer ma commande') {
    this.navToReservenow();
  } else if (this.buttonName === 'Ajouter au panier') {
    const inputElement = document.getElementById(`prodQuantity${idData}`) as HTMLInputElement;
    const quantity = parseInt(inputElement.value, 10);

    const params = {
      product: {
        code: productCode,
      },
      quantity: quantity,
      addToCartType: "quickorder"
    };

    if (this.isIndirect()) {
      params['selectedB2BUnit'] = this.userDetails.wholesalerId;
    }

    this.user.get().subscribe((customer: any) => {
      if (customer) {
        let restrictionArray = [];
        const restriction = { productCode: productCode };
        restrictionArray.push(restriction);
        const restrictionParams = { restrictions: restrictionArray };

        this.baseService.post(`/products/getRestrictions?fields=DEFAULT&emulatedUser=${customer.userName}`, restrictionParams).subscribe((res) => {
          if (res && res.restrictions.length > 0) {
            const minQuantity = res.restrictions[0].minQuantity;
            const maxQuantity = res.restrictions[0].maxQuantity;

            if (quantity < minQuantity || quantity > maxQuantity) {
              const errorMsgKey = quantity < minQuantity ? 'addToCartItems.inputMinimumQuantityErrorMsg' : 'addToCartItems.inputMaximumQuantityErrorMsg';
              this.translation.translate(errorMsgKey).subscribe((errorMsg) => {
                this.globalMessage.add(`${this.productInfo?.name}: ${errorMsg}`, GlobalMessageType.MSG_TYPE_ERROR);
              });
              return;
            }
          }
          this.itemAddedQuantity = quantity;
          if (!this.cartItems || this.cartItems.length === 0) {
            this.plpService.createCart(this.userDetails?.userName);
            this.createdCartSub = this.plpService.createdCart$.subscribe((cartRes) => {
              if (cartRes) {
                this.cartId = cartRes.code;
                this.plpService.updateCart(params, this.userDetails?.userName, this.cartId);
                this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe((resp) => {
                  if (resp) {
                    this.successPopover.open();
                    setTimeout(() => this.successPopover.toggle(), 2500);
                  }
                });
              }
            });
          } else {
            this.cartId = this.cartId || (this.cartItems.length > 0 ? this.cartItems[this.cartItems.length - 1].code : undefined);
            this.plpService.updateCart(params, this.userDetails?.userName, this.cartId);
            this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe((res) => {
              if (res) {
                this.successPopover.open();
                setTimeout(() => this.successPopover.toggle(), 2500);
              }
            });
          }
        });
      }
    });
  }
}

  isIndirect(): boolean {
    return this.productInfo?.productType && this.productInfo?.productType === 'INDIRECT';
  }

  ngDoCheck() {
    if (!this.successPopover?.isOpen() && this.clearSearch) {
      this.quickOrderDetail = false;
      this.clearSearch = false;
      this.clearSearchValue = true;
    }
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
  }
  navToReservenow() {
    this.route.goByUrl('/influenza/category');
  }
  navToIndirectPLP() {
    this.route.goByUrl('/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }

  performQuantityComputation(
    product,
    operator,
    prodCode,
    stepQuantity,
    minQuantity,
    maxQuantity
  ): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;
    try {
      ProductUtils.alterQuantity(
        operator,
        prodCode,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart',
        product.numberOfDosesPerUnit
      );
      const inputElement = document.getElementById(`prodQuantity${this.productInfo.code}q`) as HTMLInputElement;
                     this.userInput = inputElement.value; // Update userInput

                     // Always call the rounding logic after updating the quantity
                     this.roundToNearestMultipleOfStepQuantity(stepQuantity);
                     this.updateButtonStates(stepQuantity);
                     this.cdr.detectChanges();

    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }
  addtoFluCart() {
    this.route.goByUrl('/influenza/category');
  }

  roundToNearestMultipleOfStepQuantity(stepQuantity: number) {
    const parsedInput = parseFloat(this.userInput);
    if (!isNaN(parsedInput) && parsedInput > 0) {
        if (parsedInput % stepQuantity !== 0) {
            this.roundedValue = Math.ceil(parsedInput / stepQuantity) * stepQuantity;
        } else {
            this.roundedValue = parsedInput;
        }

        if (this.roundedValue !== null) {
            this.userInput = this.roundedValue.toString();
            this.dividedValue = this.roundedValue / stepQuantity;

            // Update boxesQuantity based on the rounded value
            this.boxesQuantity = Math.ceil(this.roundedValue / this.productInfo.numberOfDosesPerUnit);
        }
    } else {
        // Handle invalid input
        this.roundedValue = null;
        this.dividedValue = null;
        this.boxesQuantity = null;
    }
    // Enable/disable the minus button based on the current value
    this.updateButtonStates(stepQuantity);
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  updateButtonStates(stepQuantity: number): void {
      const inputElement = document.getElementById(`prodQuantity${this.productInfo.code}q`) as HTMLInputElement;
      const quantity = parseInt(inputElement.value, 10) || 0;

      const minusButton = document.getElementById(`minusIcon${this.productInfo.code}q`);
      const plusButton = document.getElementById(`plusIcon${this.productInfo.code}q`);

      // Enable minus button if quantity is greater than the minimum step quantity
      if (quantity > stepQuantity) {
        minusButton?.classList.remove('disabled');
      } else {
        minusButton?.classList.add('disabled');
      }

      // Enable plus button if quantity is less than some max value (if applicable)
      if (quantity < 9999) {
        plusButton?.classList.remove('disabled');
      } else {
        plusButton?.classList.add('disabled');
      }
    }

  onProductSelected(product) {
    if (product?.code !== undefined) {
      this.productInfo = product;
      this.quickOrderDetail = true;
    let calculatedQty = Math.ceil(this.productInfo?.quantityPerUnit / this.productInfo?.numberOfDosesPerUnit);
    this.boxesQuantity =  calculatedQty === 0 ? 1 : calculatedQty
    } else if (product?.code === undefined) {
      this.quickOrderDetail = false;
    }
  }
  ngOnDestroy() {
    this.userDataSub?.unsubscribe();
    this.updatedCartDetailsSub?.unsubscribe();
    this.updatedCartSub?.unsubscribe();
    this.allCartsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
  }
}
