<div class="modal-header">
  <h4 class="modal-title-top20">{{'selectVaccines.product_Details'|cxTranslate}}</h4>
  <div class="cls-btn">
    <img src="../../assets/icons/close.png" (click)="close()" alt="close">
  </div>
</div>
<div class="modal-body">
  <div class="row row-20 product-details pb-3" id="leftCont-rightNav">
    <section class="row row-20">
      <section class="container-pdp">
        <aside aria-label="Product Details" class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right">
          <img *ngIf="productDetails?.images != undefined && productDetails?.images !=='';else undefinedImg"
            class="image-prod" src="{{baseUrl}}{{productDetails.images[0]?.url}}" alt="{{productDetails?.name}}">
          <ng-template #undefinedImg>
            <img class="image-prod" [src]="fallbackImage" alt="{{productDetails?.name}}">
          </ng-template>
        </aside>
        <section class="col-xs-12 col-sm-8 col-md-8 left-section pb-2 float-left">
          <section class="row">
            <section class="col-md-10">
              <h3 class="pdp-title" [innerHTML]="productDetails?.name"></h3>
              <p class="desc-class" [innerHTML]="productDetails?.description"></p>
            </section>
            <section class="col-md-2 avail-align ">
              <span *ngIf="!productDetails.available && productDetails.discontinued===false" class="order-Accept">
                {{productDetails?.availabilityText ? productDetails?.availabilityText :
                ('selectVaccines.product_unavailable'|cxTranslate) }}
              </span>
              <span class="order-Accept" *ngIf="productDetails.discontinued===true">
                {{'selectVaccines.product_discontinued'|cxTranslate}}</span>
              <span class="order-Priority"
                *ngIf="productDetails.onsn === 'ON' && productDetails.discontinued===false && productDetails.available===true">
                {{'selectVaccines.available_now'|cxTranslate}}
              </span>
            </section>
          </section>

          <br>
          <section class="batch-info">
            <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.batchNumber'|cxTranslate}}
              <span class="desc-details">{{productDetails?.batchInfo?.batchNumber}}</span>
            </p>
            <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.expiryDate'|cxTranslate}} :
              <span class="desc-details">{{productDetails?.batchInfo?.expiryDate}}</span>
            </p>
            <p class="desc-title" *ngIf="!hospital && productDetails?.cipCode">{{'plp.cipCode'|cxTranslate}} :
              <span class="desc-details">{{productDetails?.cipCode}}</span>
            </p>
            <p class="desc-title" *ngIf="hospital && productDetails?.ucdCode">{{'plp.ucdCode'|cxTranslate}} :
              <span class="desc-details">{{productDetails?.ucdCode}}</span>
            </p>
            <hr class="line">

            <section class="third-col">
              <section class="row unit-calc">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                  <b>{{'plp.catalogPrice'|cxTranslate}}</b>
                  <section class="number">
                    <span
                      *ngIf="productDetails?.catalogPrice == undefined || productDetails?.catalogPrice?.value === undefined">-</span>
                    <span *ngIf="productDetails?.catalogPrice && productDetails?.catalogPrice?.value !== undefined">
                      <span>{{productDetails?.catalogPrice?.value | localCurrency}}</span>
                    </span>
                  </section>
                </section>

                <section class="bottom-spacing availability-text"
                  *ngIf="!productDetails?.discontinued && !productDetails?.sapAllocation && !productDetails?.flu">
                </section>
              </section>
<!--              PurchasePrice-->
              <section class="row unit-calc">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                  <b>{{'plp.purchasePrice'|cxTranslate}}</b>
                  <section class="number">
                    <span
                      *ngIf="productDetails?.price == undefined || productDetails?.price?.value === undefined">-</span>
                    <span *ngIf="productDetails?.price && productDetails?.price?.value !== undefined">
                      <span>{{productDetails?.price?.value | localCurrency}}</span>
                    </span>
                  </section>
                </section>
              </section>
              <hr class="line">
              <p [innerHTML] = "productDetails?.commercialPolicy"></p>
              <section *ngIf="!productDetails?.discontinued && productDetails?.available"
                class="products-accordion clear-fix pt2">
                <section class="panel-group">
                  <section class="panel panel-default" *ngIf="productDetails?.volumePrices?.length > 0">
                    <section class="panel-heading panel-endborder">
                      <section class="first-level-acc">
                        <label><img src="../../assets/icons/price-master.png" class="accordion-img" alt="">
                          {{'selectVaccines.volumePrice'|cxTranslate}}</label>
                        <span data-toggle="collapse" data-parent="#accordion" href="#pdp-list1" class="acc-plus-icon fa"
                          aria-expanded="panelVolumeExpanded" (click)="panelVolumeExpanded = !panelVolumeExpanded"
                          [ngClass]="panelVolumeExpanded?'fa-minus':'fa-plus collapsed'"></span>
                      </section>
                    </section>
                    <section id="pdp-list1" class="panel-collapse collapse" aria-expanded="panelVolumeExpanded"
                      (click)="panelVolumeExpanded = !panelVolumeExpanded" [ngClass]="panelVolumeExpanded?'in':''">
                      <section class="panel-body">
                        <section class="discount-list" id="vol-price-pdp">
                          <table aria-describedby="pdpTable">
                            <thead>
                              <th id=""></th>
                            </thead>
                            <tbody *ngFor="let prices of productDetails?.volumePrices; let i=index">
                              <tr>
                                <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}} {{'plp.units'|cxTranslate}}:
                                </td>
                                <td>{{prices?.value | localCurrency}}</td>
                                <td><span *ngIf="i>0" class="save-price">{{'plp.save'|cxTranslate}}
                                    {{productDetails?.volumePrices[0].value -
                                    productDetails?.volumePrices[i].value | localCurrency}}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <!-- product updates-->
              <section>
                <section class=" products-accordion clear-fix pt2 ">
                  <section class="panel-group ">
                    <!-- update2 -->
                    <section class="panel panel-default " *ngIf="productDetails?.productUpdates ">
                      <section class="panel-heading ">
                        <section class="first-level-acc ">
                          <label><img src="../../assets/icons/info.png " class="accordion-img "
                              alt=" ">{{'plp.productUpdate'|cxTranslate}}
                          </label> <span data-toggle="collapse " data-parent="#accordion " href="#pdp-list3 "
                            class="acc-plus-icon fa " aria-expanded="panelProductExpanded "
                            (click)="panelProductExpanded=! panelProductExpanded "
                            [ngClass]="panelProductExpanded? 'fa-minus': 'fa-plus collapsed' "></span>
                        </section>
                      </section>
                      <section id="pdp-list3 " class="panel-collapse collapse " aria-expanded="panelProductExpanded "
                        (click)="panelProductExpanded=! panelProductExpanded "
                        [ngClass]="panelProductExpanded? 'in': '' ">
                        <section class="panel-body productupdate-p ">
                          {{productDetails?.productUpdates}}</section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <!--product updates end-->
            </section>
          </section>
        </section>
      </section>

    </section>
  </div>
</div>
