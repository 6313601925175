import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';
import { SharedService } from '../shared/services/shared.service';
import {GET_ORDER_DELIVERY_NOTES} from "../shared/config/app.config";

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService {

  public userData = new BehaviorSubject<any>(null);
  userData$ = this.userData.asObservable();

  public orderDetails = new BehaviorSubject<any>(null);
  orderDetails$ = this.orderDetails.asObservable();

  public addCartDetails = new BehaviorSubject<any>(null);
  addCartDetails$ = this.addCartDetails.asObservable();

  public deleteOrderItems = new BehaviorSubject<any>(false);
  deleteOrderItems$ = this.deleteOrderItems.asObservable();

  public orderCartItems = new BehaviorSubject<any>(false);
  orderCartItems$ = this.orderCartItems.asObservable();

  public createdOrderCart = new BehaviorSubject<any>(false);
  createdOrderCart$ = this.createdOrderCart.asObservable();

  public saveCartOrderSuccess = new BehaviorSubject<any>(false);
  saveCartOrderSuccess$ = this.saveCartOrderSuccess.asObservable();

  public invalidOrderCartName = new BehaviorSubject<any>(false);
  invalidOrderCartName$ = this.invalidOrderCartName.asObservable();

  public orderSaveCartName = new Subject<any>();

  public deliveryNotes = new BehaviorSubject<any>(null);
  deliveryNotes$ = this.deliveryNotes.asObservable();

  constructor(
    public baseService: BaseService, public sharedService: SharedService,
    public excelService: ExcelDownloadService
  ) {

  }

  public getOrderHistory(params, filters?) {
    this.baseService
      .get(`${config.GET_ORDER_HISTORY}${params.userId}/orders`, filters)
      .subscribe((res) => {
        this.userData.next(res);
      });
  }

  public getOrderDetails(userId, code) {
    const url = config.GET_ORDER_HISTORY + userId + '/orders/' + code;
    this.baseService.get(url).subscribe((res) => {
      if (res) {
        this.orderDetails.next(res);
      }
    });
  }
  addToCart(userId, data, cartId): void {
    this.baseService.post(config.USER_ACCOUNT + userId + '/carts/' + cartId + '/bulk-add-to-cart', data).subscribe((res) => {
      this.addCartDetails.next(res);
    });
  }
  clearCart(childCartId, userId): void {
    this.baseService.delete(config.USER_ACCOUNT + userId + '/carts/' + childCartId).subscribe((res) => {
      this.deleteOrderItems.next(true);
    });
  }
  // cart API
  getCarts(userId): void {
    this.orderCartItems.next(false);
    if (userId) {
      this.baseService.get(config.USER_ACCOUNT + userId + '/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false').subscribe((res) => {
        this.orderCartItems.next(res);
      });
    }
  }

  createCart(userId): void {
    this.baseService.post(config.USER_ACCOUNT + userId + '/carts?fields=DEFAULT', {}).subscribe((res) => {
      this.createdOrderCart.next(res);
    });
  }

  public downloadXls(data, name) {
    this.excelService.exportAsExcelFile(data, name, true);
  }

  public downloadPdf(params): void {
    this.baseService
      .getPdf(config.DOWNLOAD_INVOICE_CREDIT_PDF, { invoiceNumber: params })
      .subscribe((res) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }

  saveCartOrderDetails(userName, code, cartName): void {
    this.orderSaveCartName.next(false);
    this.invalidOrderCartName.next(false);
    this.saveCartOrderSuccess.next(false);
    this.baseService.patch(config.USER_ACCOUNT + userName + '/orders/' + code +
      '/save?fields=DEFAULT&saveCartName=' + cartName, {}).subscribe((res) => {
        if (res) {
          this.orderSaveCartName.next(res);
          this.saveCartOrderSuccess.next(true);
        }
      },
        (err) => {
          this.invalidOrderCartName.next(err);
        }
      );
  }

  getSavedOrderName(): Observable<any> {
    return this.orderSaveCartName.asObservable();
  }

  public getDeliveryNotes(params):void {
    this.baseService
      .get(`${config.GET_ORDER_DELIVERY_NOTES}${params.userId}/deliveryNotes/search?orderCode=${params.orderCode}`,)
      .subscribe((res) => {
        this.deliveryNotes.next(res);
      });
  }

  public downloadDeliveryNotePdf(params): void {
    this.baseService
      .getPdf(`${config.GET_ORDER_DELIVERY_NOTES}${params.userId}/deliveryNotes/get?${params.paramStr}`,)
      .subscribe((res) => {
        const file = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }
}
