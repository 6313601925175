<section class="container-fluid accountField-ContainerFluid">
  <section class="row accnt-Div ">
    <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 preference-content">
      <form action="">
        <section class="">
          <p class="locview-info">{{'account.select'| cxTranslate}}</p>
          <section class="col-sm-7 col-md-7 col-lg-7   locleft">
            <section class="ship-address">
              <section id="location-select">
                <section class="location-icon">
                  <span class="glyph-icon">
                    <i class="fas fa-map-marker-alt icon mr-3"></i>
                  </span>
                </section>
                <section class="location-Details">
                  <span class="selected-loc">
                    {{selectedData?.companyName}}</span>
                  <span class="change-loc" role="button" *ngIf="changeLocation"
                        (click)="open(content)">{{'account.changeLocation'|
                    cxTranslate}}</span>
                </section>
                <section class="custome-modal">
                  <ng-template #content let-modal>
                    <section class="modal-header">
                      <button type="button" class="close" aria-label="Close" (click)='onCancel()'
                        (click)="modal.dismiss('Cross click')">
                        <img alt="" src="../../assets/icons/close.png">
                      </button>
                      <h4 class="modal-title" id="modal-basic-title">{{'account.selectLocation'| cxTranslate}}</h4>
                    </section>
                    <section class="modal-body">
                      <section class="input-group loc-SearchBar">
                        <span class="input-group-btn">
                          <button class="btn" type="button">
                            <span class="loc-Search-Icon">
                            </span>
                          </button>
                          <input type="text" #clientName (keyup)="SearchClientName(clientName.value)"
                            class="form-control searchboxnew"
                            placeholder="{{'account.filterByLocation'| cxTranslate}}	" />
                        </span>
                      </section>
                      <hr />
                      <section class="scroll-data account-scroll-data">
                      <section class="custom-control custom-radio radio_grp  w-100 m-0">
                        <table class="w-100" *ngFor="let data of searchedClientList;  let i = index" aria-describedby="locTable">
                          <tr class="sample-header">
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                          <tr class="line-location" [ngClass]="{'label-location': (i !== searchedClientList.length-1)}">
                            <input type="radio" (change)='onOptionsSelected(data)' [id]="data.id"
                              [checked]="selectedData.id == data.id" name="customRadio" class="custom-control-input" />
                            <label class="custom-control-label secloct" [for]="data.id">
                              {{data?.companyName}} {{"("+data?.sapCustomerID+")"}}
                            </label>
                            <p class="labelpp"> {{data?.line1}}, {{data?.line2}}, {{data?.town}},
                               {{data?.postalCode}}
                            </p>
                          </tr>
                        </table>
                      </section>
                    </section>
                    </section>
                    <section class="modal-footer">
                      <button type="button" class="btn location-done" (click)='onSubmit()'
                        (click)="modal.close('Save click')">
                        {{'account.done'| cxTranslate}}
                      </button>
                    </section>
                  </ng-template>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section class="shipping-address shipping-address-loc">
          <section class="col-xs-12 col-sm-12 col-md-12  Fields-container ">
            <section class="contact-details name-edit">
              <h3>{{'account.shippingAddress'| cxTranslate}}</h3>
              <section class="post-edit">
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.facilityName'| cxTranslate}}</label>
                    <span>{{usergetDataObj?.name || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.address'| cxTranslate}}</label>
                    <span>
                      <ng-container *ngIf="selectedData?.line2"> {{ selectedData?.line2 }}</ng-container>
                      {{ selectedData?.line1 }}
                    </span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.postalCode'| cxTranslate}}</label>
                    <span>{{selectedData?.postalCode || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.city'| cxTranslate}}</label>
                    <span>{{selectedData?.town}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.landLine'| cxTranslate}}</label>
                    <span>{{selectedData?.phone | phoneFormat}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.mobile'| cxTranslate}}</label>
                    <span>{{selectedData?.cellphone | phoneFormat}}</span>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <hr>
        <section class="shipping-address shipping-address-loc">
          <section class="col-xs-12 col-sm-12 col-md-12  Fields-container ">
            <section class="contact-details name-edit">
              <h3>{{'account.contactDetails'| cxTranslate}}</h3>
              <section class="post-edit">
                <h4>{{'account.pharmacyOwnerPosition'| cxTranslate}}</h4>
                <section class="Fields-container">
                  <label class="Fields-label">{{'account.pharmacyOwnerName'| cxTranslate}}</label>
                  <span>{{contactAddress?.firstName}} {{contactAddress?.lastName}}</span>
                </section>
                <section class="Fields-container">
                  <label class="Fields-label">{{'account.pharmacyOwnerPhone'| cxTranslate}}</label>
                  <span>{{contactAddress?.phone | phoneFormat}}</span>
                </section>
                <section class="Fields-container">
                  <label class="Fields-label">{{'account.pharmacyOwnerAddress'| cxTranslate}}</label>
                  <span>
                    <ng-container *ngIf="contactAddress?.line2"> {{ contactAddress?.line2 }}</ng-container>
                    {{ contactAddress?.line1 }}
                  </span><br>
                  <span>
                    {{ contactAddress?.postalCode }}
                    {{ contactAddress?.town }}
                  </span>
<!--                  <br>-->
<!--                  <span>{{contactAddress?.town}}, {{contactAddress?.country?.name}}</span>-->
                </section>
                <section class="Fields-container">
                  <label class="Fields-label">{{'account.pharmacyOwnerEmail'| cxTranslate}}</label>
                  <span>{{contactAddress?.email}}</span>
                </section>
              </section>
            </section>
          </section>
        </section>
      </form>
    </section>
  </section>
</section>
