<div class="bc-makePayment-Div">
    <span class="back-To-makePayment">
        <img src="assets/img/chevron-left.svg" alt="left" class="svg-Images">
        <a href="javascript: void(0);" [routerLink]="['/', 'my-account/invoices']" class="underline">
            Back to make a payment</a>
    </span>
</div>
<section class="row makePaymentView" id="productList-area">
    <div class="col-md-10 col-sm-9 pay-section float-right">
        <app-payment-header [selectedHeader]="header" [tableCategory]="creditInvoice"></app-payment-header>
        <div *ngIf="invoicePaymentList?.length > 0" class="invoice-form">
            <form [formGroup]="creditPaymentForm">
                <div id="left-box" class="
            col-xs-12 col-sm-12 col-md-6
            p0
            Fields-container
            register-zip
            paymentStep1View
            Fields-container-default-error
            form-group
            add-input
          ">
                    <label for="person_name" class="Fields-label">
                        {{
                        "makeAPayment.nameofPersonAuthorizingPayment" | cxTranslate
                        }}</label>
                    <input id="person_name_unique" name="authorizerName" data-valid="valid_name"
                        class="Fields-input auth-name form-control" formControlName="personName"
                        (keypress)="omit_special_char($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.personName.errors }" data-di-id="#person_name"
                        autocomplete="off" />
                    <section *ngIf="submitted && f.personName.errors" class="invalid-feedback">
                        <section *ngIf="f.personName.errors.required">
                            {{ "makeAPayment.fieldRequired" | cxTranslate }}
                        </section>
                    </section>
                </div>

                <section *ngIf="!isAsmEnabled" id="right-box" class="
            col-xs-9 col-sm-6 col-md-5
            p0
            Fields-container
            register-zip
            paymentStep1View
          ">
                    <label for="nonASMToMail" class="Fields-label credit_name">
                        {{ "makeAPayment.EmailforConfirmation" | cxTranslate }}</label> <span class="user-email">{{
                        userData?.email
                        }}</span>
                </section>
                <section *ngIf="isAsmEnabled" id="right-box" class="
        col-xs-9 col-sm-6 col-md-5
        p0
        Fields-container
        register-zip
        paymentStep1View
        req-fields" [formGroup]="editStdForm">
                    <section class="col-md-12 emails-ngSelect-Div">
                        <label for="nonASMToMail" class="Fields-label credit_name">
                            {{ "makeAPayment.ASMEmailforConfirmation" | cxTranslate }}{{ "addToCartItems.optional" |
                            cxTranslate }} :</label>
                        <ng-select class="w-100 email-Styling detailsEmail" [items]="emailsArray" bindLabel="emailId"
                            bindValue="emailId" [multiple]="false" formControlName="emailId" [clearable]="false"
                            [(ngModel)]="chosenEmail" placeholder="Please Select" dropdownPosition="bottom">
                        </ng-select>
                    </section>
                    <section class="col-md-12 add-Email" *ngIf="chosenEmail === 'Add Other E-mail Address'">
                        <input class="form-control edit-Form-Styling" type="email" formControlName="newemailId"
                            maxlength="241" [ngClass]="{
              invalidClass:
                editStdForm.get('newemailId').invalid &&
                (editStdForm.get('newemailId').touched ||
                  editStdForm.get('newemailId').dirty)
            }" />
                        <span class="txt-error" *ngIf="
              editStdForm.get('newemailId').invalid &&
              (editStdForm.get('newemailId').dirty ||
                editStdForm.get('newemailId').touched) &&
              editStdForm.get('newemailId').value !==0
            ">
                            You have entered an invalid email address
                        </span>
                    </section>
                </section>
                <section *ngIf="invoicePaymentList.length > 0" class="tablemain-padding">
                    <table aria-describedby="creditsTable" id="applyCreditsTable"
                        class="payment-table display dataTable no-footer">
                        <thead>
                            <tr class="heading-table">
                                <th id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-label="Payment-option: activate to sort column ascending">
                                    {{ "makeAPayment.payment_option" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                                    {{ "makeAPayment.invoice#" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col3" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-label="Due date: activate to sort column ascending">
                                    {{ "makeAPayment.due_date" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col4" class="pr8 sorting" tabindex="0" class=""
                                    aria-controls="applyCreditsTable"
                                    aria-label="Invoice balance: activate to sort column ascending">
                                    {{ "makeAPayment.invoice_balance" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col5" class="pr8 sorting_desc" tabindex="0" class=""
                                    aria-controls="applyCreditsTable"
                                    aria-label="Payment amount: activate to sort column ascending">
                                    {{ "makeAPayment.payment_amount" | cxTranslate }} &nbsp;
                                </th>

                                <th id="table1col7" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-label="Payment date: activate to sort column ascending">
                                    {{ "makeAPayment.payment_date" | cxTranslate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="datatable-row popup-comp">
                            <tr class="odd" *ngFor="let data of invoicePaymentList">
                                <td headers="table1col1">{{ paymentOptionDefault }}</td>
                                <td headers="table1col2" class="darkblue sorting_1">
                                    <a *ngIf="data?.docType.startsWith('R')"
                                        href="javascript: void(0);" class="underline"
                                        (click)="invoicePdf(data?.refDocNo)">
                                        {{ data?.refDocNo }}
                                    </a>
                                    <span
                                        *ngIf="data?.docType.startsWith('D')">
                                        {{ data?.refDocNo }}
                                    </span>
                                    <a *ngIf="data?.docType === 'YR' &&
                                              (data?.dochdrtxt.startsWith('FR50') ||
                                               data?.dochdrtxt.startsWith('1888'))"
                                       href="javascript: void(0);"
                                       class="underline"
                                       (click)="invoicePdfWithSystemId(data.refhdr, data.systemId)">
                                      {{ data.refhdr }}
                                    </a>
                                    <input id="invoiceDatas0.credit" name="invoiceDatas[0].credit" type="hidden"
                                        value="92258122" data-di-id="#invoiceDatas0.credit" />
                                </td>
                                <td headers="table1col3">{{ data?.dueDate }}</td>
                                <td headers="table1col4" class="right-text">
                                    {{ data?.invoiceBalance | localCurrency }}
                                    <input id="invoiceDatas0.invoiceBalance" name="invoiceDatas[0].invoiceBalance"
                                        value="1479.49" type="hidden" data-di-id="#invoiceDatas0.invoiceBalance" />
                                </td>

                                <td headers="table1col5" class="right-text">
                                    {{ data?.paymentAmount }}
                                </td>

                                <td headers="table1col7">
                                    {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <section class="credits-table-2column">
                        <div class="row credits-row-rwd" *ngFor="let data of invoicePaymentList">
                            <div class="column creadits-header-columnrwd">
                                <section>
                                    <p id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                        aria-label="Payment-option: activate to sort column ascending">
                                        {{ "makeAPayment.payment_option" | cxTranslate }}
                                    </p>
                                    <p id="table1col2" class="sorting_desc" tabindex="0"
                                        aria-controls="applyCreditsTable" aria-sort="descending"
                                        aria-label="Invoice #: activate to sort column ascending">
                                        {{ "makeAPayment.invoice#" | cxTranslate }}
                                    </p>
                                    <p id="table1col3" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                        aria-label="Due date: activate to sort column ascending">
                                        {{ "makeAPayment.due_date" | cxTranslate }}
                                    </p>
                                    <p id="table1col4" class="pr8 sorting" tabindex="0" class="right-text"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Invoice balance: activate to sort column ascending">
                                        {{ "makeAPayment.invoice_balance" | cxTranslate }}
                                    </p>
                                    <p id="table1col5" class="pr8 sorting_desc" tabindex="0" class="right-text"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Payment amount: activate to sort column ascending">
                                        {{ "makeAPayment.payment_amount" | cxTranslate }}
                                    </p>

                                    <p id="table1col7" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                        aria-label="Payment date: activate to sort column ascending">
                                        {{ "makeAPayment.payment_date" | cxTranslate }}
                                    </p>
                                </section>
                            </div>
                            <div class="column credits-desc-column">
                                <section>
                                    <p headers="table1col1">{{ paymentOptionDefault }}</p>
                                    <p headers="table1col2" class="darkblue sorting_1">
                                        <a *ngIf="data?.docType.startsWith('R')"
                                            href="javascript: void(0);" class="underline"
                                            (click)="invoicePdf(data?.refDocNo)">
                                            {{ data?.refDocNo }}
                                        </a>
                                        <span
                                            *ngIf="data?.docType.startsWith('D')">
                                            {{ data?.refDocNo }}
                                        </span>
                                        <a *ngIf="data?.docType === 'YR' &&
                                                  (data?.dochdrtxt.startsWith('FR50') ||
                                                   data?.dochdrtxt.startsWith('1888'))"
                                           href="javascript: void(0);"
                                           class="underline"
                                           (click)="invoicePdfWithSystemId(data.refhdr, data.systemId)">
                                          {{ data.refhdr }}
                                        </a>
                                        <input id="invoiceDatas0.credit" name="invoiceDatas[0].credit" type="hidden"
                                            value="92258122" data-di-id="#invoiceDatas0.credit" />
                                    </p>
                                    <p headers="table1col3">{{ data?.dueDate }}</p>
                                    <p class="table1col4">
                                        {{ data?.invoiceBalance | localCurrency }}
                                        <input id="invoiceDatas0.invoiceBalance" name="invoiceDatas[0].invoiceBalance"
                                            value="1479.49" type="hidden" data-di-id="#invoiceDatas0.invoiceBalance" />
                                    </p>

                                    <p headers="table1col5" class="right-text">
                                        {{ data?.paymentAmount }}
                                    </p>

                                    <p headers="table1col7">
                                        {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                                    </p>
                                </section>
                            </div>
                        </div>
                    </section>

                    <section class="light-line"></section>

                    <!--  -->
                </section>

                <section class="row online-invoices sec-part">
                    <aside aria-labelledby="paymentSection" class="col-xs-12 po-right open_aside width-50 bottom-s1">
                        <section>
                            <h3 class="sorting payment-method pay-title" tabindex="0" aria-controls="applyCreditsTable"
                                aria-label="Payment method: activate to sort column ascending">
                                {{ "makeAPayment.payment_method" | cxTranslate }}
                            </h3>
                            <div class="existingDropdown asses_dropdown">
                                <div id="existingCard" class="sorting_1 payment-details radio_grp">
                                    <input id="existing1" type="radio" name="billing_loc0" checked="data.checked"
                                        data-di-id="#existing1" />
                                    <span aria-labelled="existing1" class="card-details-padding">{{
                                        "makeAPayment.existing_card" |
                                        cxTranslate }}</span>
                                </div>
                                <section class="
                    sort-by-region
                    fst-dropdown
                    custom-selectpay
                    select-pay-adj
                  ">
                                    <ng-select [(ngModel)]="selectedCard" (change)="cardChange(selectedCard)"
                                        [ngModelOptions]="{ standalone: true }">
                                        <ng-option *ngFor="let data of formatCardData" [value]="data">{{
                                            data?.displayCardNumber }}
                                        </ng-option>
                                    </ng-select>
                                </section>
                            </div>
                            <div class="sorting_1 radio_grp show-content card-details" *ngIf="!isAsmEnabled">
                                <input id="new_card" type="radio" data-toggle="modal" data-keyboard="true"
                                    (click)="open()" name="billing_loc0" class="newCard"
                                    data-di-id="di-id-b58cbc10-235349aa" />
                                <span>{{ "makeAPayment.new_card" | cxTranslate }}</span>
                                <input id="newCrmID" name="invoiceDatas[0].newCrmCardId" type="hidden" value=""
                                    data-di-id="#newCrmID" />
                            </div>
                            <img src="../../../assets/icons/visa.png" class="card-type"
                                *ngIf="temporaryCardDetails?.cardType === 'VISA'" alt="VISA" />
                            <img src="../../../assets/icons/mastercard.png" class="card-type" alt="MASTER"
                                *ngIf="temporaryCardDetails?.cardType === 'MC'" />
                            <span class="card-no"
                                *ngIf="temporaryCardDetails">{{temporaryCardDetails?.displayCardNumber}}</span>
                        </section>
                    </aside>
                    <aside aria-labelledby="paymentSection" class="col-xs-12 po-right float-right open_aside bottom-s2">
                        <section class="row row-20">
                            <section class="col-xs-6 col-md-6 paynow-title">
                                <h3 class="title p5">
                                    {{ "makeAPayment.pay_now" | cxTranslate }}
                                </h3>
                            </section>
                            <section class="col-xs-6 col-md-6">
                                <p class="total amount">{{ totalInvoiceAmount | localCurrency }}</p>
                            </section>
                        </section>
                        <p class="para">
                            {{ "makeAPayment.totalamountinfo" | cxTranslate }}
                        </p>
                        <section class="row paytotal">
                            <section class="col-xs-6 col-md-6 pl0">
                                <h3 class="title bold">
                                    {{ "makeAPayment.payment_total" | cxTranslate }}
                                </h3>
                            </section>
                            <section class="col-xs-6 col-md-6 pr0">
                                <p class="total bold">{{ totalInvoiceAmount | localCurrency }}</p>
                            </section>
                        </section>
                        <button type="submit" class="reviewbtn" data-di-id="di-id-5896ea52-8b55cbae"
                            (click)="Continue('Invoice')">
                            {{ "makeAPayment.continue_button_txt" | cxTranslate }}
                        </button>
                        <a href="javascript:void(0)" role="presentation" data-di-id="di-id-7ee5cf4b-3449b0d5"><button
                                type="button" (click)="back()" class="cancelbtn">
                                {{ "makeAPayment.cancel" | cxTranslate }}
                            </button></a>
                    </aside>
                </section>
            </form>
        </div>

        <!-- For Credit Page -->
        <div *ngIf="creditPaymentList.length > 0" class="invoice-form">
            <form [formGroup]="creditPaymentForm">
                <div id="left-box" class="
            col-xs-12 col-sm-12 col-md-6
            p0
            Fields-container
            register-zip
            paymentStep1View
            Fields-container-default-error
            form-group
            add-input
          ">
                    <label for="person_name" class="Fields-label">{{
                        "makeAPayment.nameofPersonAuthorizingCredit" | cxTranslate
                        }}</label>
                    <input id="person_name" name="authorizerName" data-valid="valid_name"
                        class="Fields-input auth-name form-control" formControlName="personName"
                        (keypress)="omit_special_char($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.personName.errors }" data-di-id="#person_name"
                        autocomplete="off" />
                    <section *ngIf="submitted && f.personName.errors" class="invalid-feedback">
                        <section *ngIf="f.personName.errors.required">
                            {{ "makeAPayment.fieldRequired" | cxTranslate }}
                        </section>
                    </section>
                </div>

                <section *ngIf="!isAsmEnabled" id="right-box" class="
            col-xs-9 col-sm-6 col-md-5
            p0
            Fields-container
            register-zip
            paymentStep1View
          ">
                    <label for="nonASMToMail" class="Fields-label credit_name">
                        {{ "makeAPayment.EmailforConfirmation" | cxTranslate }}</label>
                    <span class="user-email">{{ userData?.email}}</span>
                    <input id="nonASMToMail" name="nonASMToMail" [value]="userData?.email" type="hidden"
                        data-di-id="#nonASMToMail" />
                </section>
                <section *ngIf="isAsmEnabled" id="right-box" class="
        col-xs-9 col-sm-6 col-md-5
        p0
        Fields-container
        register-zip
        paymentStep1View
        req-fields" [formGroup]="editStdForm">
                    <section class="col-md-12 emails-ngSelect-Div">
                        <label for="nonASMToMail" class="Fields-label credit_name">
                            {{ "makeAPayment.ASMEmailforConfirmation" | cxTranslate }}{{ "addToCartItems.optional" |
                            cxTranslate }} :</label>
                        <ng-select class="w-100 email-Styling detailsEmail" [items]="emailsArray" bindLabel="emailId"
                            bindValue="emailId" [multiple]="false" formControlName="emailId" [clearable]="false"
                            [(ngModel)]="chosenEmail" placeholder="Please Select" dropdownPosition="bottom">
                        </ng-select>
                    </section>
                    <section class="col-md-12 add-Email" *ngIf="chosenEmail === 'Add Other E-mail Address'">
                        <input class="form-control edit-Form-Styling" type="email" formControlName="newemailId"
                            maxlength="241" [ngClass]="{
              invalidClass:
                editStdForm.get('newemailId').invalid &&
                (editStdForm.get('newemailId').touched ||
                  editStdForm.get('newemailId').dirty)
            }" />
                        <span class="txt-error" *ngIf="
              editStdForm.get('newemailId').invalid &&
              (editStdForm.get('newemailId').dirty ||
                editStdForm.get('newemailId').touched) &&
              editStdForm.get('newemailId').value !==0
            ">
                            You have entered an invalid email address
                        </span>
                    </section>
                </section>
                <section *ngIf="creditPaymentList.length > 0" class="col-md-12 tablemain-padding">
                    <table aria-describedby="Invoice Table" id="applyCreditsTable"
                        class="credit-table display dataTable no-footer">
                        <thead>
                            <tr class="heading-table">
                                <th id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-label="Payment-option: activate to sort column ascending">
                                    {{ "makeAPayment.credit#" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col2" class="sorting_desc" tabindex="0" aria-controls="applyCreditsTable"
                                    aria-sort="descending" aria-label="Invoice #: activate to sort column ascending">
                                    {{ "makeAPayment.apply_to_invoice" | cxTranslate }}
                                </th>
                                <th id="table1col3" class="sorting" tabindex="0" class="right-text"
                                    aria-controls="applyCreditsTable"
                                    aria-label="Due date: activate to sort column ascending">
                                    {{ "makeAPayment.credit_applied" | cxTranslate }}
                                </th>
                                <th id="table1col4" class="pr8 sorting" tabindex="0" class="right-text"
                                    aria-controls="applyCreditsTable"
                                    aria-label="Invoice balance: activate to sort column ascending">
                                    {{ "makeAPayment.credit_balance" | cxTranslate }}
                                </th>
                                <th id="table1col5" class="pr8 sorting_desc" tabindex="0" class="right-text"
                                    aria-controls="applyCreditsTable"
                                    aria-label="Payment amount: activate to sort column ascending">
                                    {{ "makeAPayment.invoice_balance" | cxTranslate }} &nbsp;
                                </th>
                                <th id="table1col6" class="sorting payment-method" tabindex="0"
                                    aria-controls="applyCreditsTable"
                                    aria-label="Payment method: activate to sort column ascending">
                                    {{ "makeAPayment.credit_applied_date" | cxTranslate }} &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody class="datatable-row popup-comp">
                            <tr class="odd" *ngFor="let data of creditPaymentList">
                                <td headers="table1col1">
                                    <a *ngIf="data?.docType.startsWith('R')"
                                        href="javascript: void(0);" class="underline"
                                        (click)="creditPdf(data?.refDocNo)">
                                        {{ data?.refDocNo }}
                                    </a>
                                    <span
                                        *ngIf="data?.docType.startsWith('D')">
                                        {{ data?.refDocNo }}
                                    </span>
                                    <a *ngIf="data?.docType === 'YR' &&
                                              (data?.dochdrtxt.startsWith('FR50') ||
                                               data?.dochdrtxt.startsWith('1888'))"
                                       href="javascript: void(0);"
                                       class="underline"
                                       (click)="invoicePdfWithSystemId(data.refhdr, data.systemId)">
                                      {{ data.refhdr }}
                                    </a>
                                </td>
                                <td headers="table1col2">{{ data?.applyToInvoice }}</td>
                                <td headers="table1col3" class="right-text">
                                    {{ data?.creditsApplied | localCurrency }}
                                </td>
                                <td headers="table1col4" class="right-text">
                                    {{ data?.creditBalance | localCurrency }}
                                </td>
                                <td headers="table1col5" class="right-text">
                                    {{ data?.invoiceBalance | localCurrency }}
                                </td>
                                <td headers="table1col6">
                                    {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <section class="invoicepayment-table-2column">
                        <div class="row invoice-2col-row-rwd" *ngFor="let data of creditPaymentList">
                            <div class="column invoice-header-rwd">
                                <section>
                                    <p id="table1col1" class="sorting" tabindex="0" aria-controls="applyCreditsTable"
                                        aria-label="Payment-option: activate to sort column ascending">
                                        {{ "makeAPayment.credit#" | cxTranslate }} &nbsp;
                                    </p>
                                    <p id="table1col2" class="sorting_desc" tabindex="0"
                                        aria-controls="applyCreditsTable" aria-sort="descending"
                                        aria-label="Invoice #: activate to sort column ascending">
                                        {{ "makeAPayment.apply_to_invoice" | cxTranslate }}
                                    </p>
                                    <p id="table1col3" class="sorting" tabindex="0" class="right-text"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Due date: activate to sort column ascending">
                                        {{ "makeAPayment.credit_applied" | cxTranslate }}
                                    </p>
                                    <p id="table1col4" class="pr8 sorting" tabindex="0" class="right-text"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Invoice balance: activate to sort column ascending">
                                        {{ "makeAPayment.credit_balance" | cxTranslate }}
                                    </p>
                                    <p id="table1col5" class="pr8 sorting_desc" tabindex="0" class="right-text"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Payment amount: activate to sort column ascending">
                                        {{ "makeAPayment.invoice_balance" | cxTranslate }} &nbsp;
                                    </p>
                                    <p id="table1col6" class="sorting payment-method" tabindex="0"
                                        aria-controls="applyCreditsTable"
                                        aria-label="Payment method: activate to sort column ascending">
                                        {{ "makeAPayment.credit_applied_date" | cxTranslate }} &nbsp;
                                    </p>
                                </section>
                            </div>
                            <div class="column invoice-desc-column">
                                <section>
                                    <p headers="table1col1">
                                        <a *ngIf="data?.docType.startsWith('R')"
                                            href="javascript: void(0);" class="underline"
                                            (click)="creditPdf(data?.refDocNo)">
                                            {{ data?.refDocNo }}
                                        </a>
                                        <span
                                            *ngIf="data?.docType.startsWith('D')">
                                            {{ data?.refDocNo }}
                                        </span>
                                        <a *ngIf="data?.docType === 'YR' &&
                                                  (data?.dochdrtxt.startsWith('FR50') ||
                                                   data?.dochdrtxt.startsWith('1888'))"
                                           href="javascript: void(0);"
                                           class="underline"
                                           (click)="invoicePdfWithSystemId(data.refhdr, data.systemId)">
                                          {{ data.refhdr }}
                                        </a>
                                    </p>
                                    <p headers="table1col2">{{ data?.applyToInvoice }}</p>
                                    <p headers="table1col3" class="right-text">
                                        {{ data?.creditsApplied | localCurrency }}
                                    </p>
                                    <p headers="table1col4" class="right-text">
                                        {{ data?.creditBalance | localCurrency }}
                                    </p>
                                    <p headers="table1col5" class="right-text">
                                        {{ data?.invoiceBalance | localCurrency }}
                                    </p>
                                    <p headers="table1col6">
                                        {{ paymentDateDefault | date: "dd/MM/yyyy" }}
                                    </p>
                                </section>
                            </div>
                        </div>
                    </section>

                    <section class="invoice-btngrps">
                        <a href="javascript:void(0)" class="focusnone" tabindex="-1"
                            data-di-id="di-id-9f8fe96-b6e2fb87">
                            <button type="button" (click)="back()" class="btn btn-default oi-white-button">
                                {{ "makeAPayment.back_btn" | cxTranslate }}
                            </button></a>
                        <button type="button" class="btn btn-default oi-blue-button reviewvalidbtn"
                            (click)="Continue('Credit')" data-di-id="di-id-7a6cb2cd-cb37948a">
                            {{ "makeAPayment.continue_button_txt" | cxTranslate }}
                        </button>
                    </section>
                </section>
            </form>
        </div>
    </div>
</section>
