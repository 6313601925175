import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { AddCardComponent } from '../../shared/modules/add-card/add-card.component';
import { AddCardService } from '../../shared/modules/add-card/add-card.service';
import { SharedService } from '../../shared/services/shared.service';
import { CommonUtils } from '../../shared/utils/common.utils';
import { InvoiceUtils } from '../../shared/utils/invoice.utils';
import { MakeAPaymentService } from '../make-a-payment.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
  sortDir = 1;
  paymentOptionDefault = 'Pay now';
  paymentDateDefault = new Date();
  creditPaymentList: any = [];
  invoicePaymentList: any = [];
  totalInvoiceAmount: any;
  cardDetails: any;
  dataType: any;
  creditPaymentForm: FormGroup;
  submitted: boolean;
  formatCardData: any;
  userData: any;
  header: string;
  creditInvoice: string;
  selectedCard = null;
  b2bUnitID: any;
  temporaryCardDetails: any;
  compNameAdBanner1 = 'InvoiceBannerComponent';
  errorMessageText = 'Please select valid payment method to proceed';
  cardApi = 'addToCartItems.cardExpMsg';
  isAsmEnabled: any;
  existingCard: any;
  emailsArray: any = [];
  editStdForm = new FormGroup({
    emailId: new FormControl(''),
    newemailId: new FormControl('', [Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  });
  chosenEmail: any;
  csAgentAuthSubscription: Subscription;
  addCardSubscription: Subscription;
  userSubscription: Subscription;
  accountIdSubscription: Subscription;
  cardDetailsSubscription: any;
  translateSubscription: Subscription;
  emailSubscription: any;
  addCardDetailsSubscription: Subscription;
  constructor(
    private readonly makeAPayment: MakeAPaymentService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly addCardService: AddCardService,
    private readonly globalMessage: GlobalMessageService,
    private readonly csAgentAuthService: CsAgentAuthService,
    private readonly translation: TranslationService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('selectedCard');
    localStorage.removeItem('cardDetails');
    localStorage.removeItem('asmEmail');
    localStorage.removeItem('creditPersonName');
    this.header = 'paymentDetails';
    this.temporaryCardDetails = null;
    this.invoicePaymentList = [];
    this.creditPaymentList = [];
    this.creditPaymentForm = this.formBuilder.group({
      personName: ['', Validators.required],
    });

    const List = this.sharedService.getSessionStorage('creditData');
    if (List !== null) {
      this.header = 'creditDetails';
      this.creditInvoice = '';
      this.creditPaymentList = List;
    } else {
      this.invoicePaymentList =
        this.sharedService.getSessionStorage('invoicesData');
      this.header = 'paymentDetails';
      this.creditInvoice = 'invoiceTable';
      // To get the Total amount
      if (this.invoicePaymentList !== null) {
        const paymentAmount = this.invoicePaymentList.map((invoicePay) =>
          invoicePay.paymentAmount.substring(1)
        );
        this.totalInvoiceAmount = paymentAmount.reduce(this.sumOfAmount, 0);
      }
    }
    this.csAgentAuthSubscription = this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      this.isAsmEnabled = res ? res : false;
      if (this.isAsmEnabled) {
        if (List !== null) {
          this.header = 'creditDetails';
          this.creditInvoice = '';
          this.creditPaymentList = List;
        } else {
          this.invoicePaymentList =
            this.sharedService.getLocalStorage('invoicesData');
          this.header = 'paymentDetails';
          this.creditInvoice = 'invoiceTable';
          // To get the Total amount
          if (this.invoicePaymentList !== null) {
            const paymentAmount = this.invoicePaymentList.map((invoicePay) =>
              invoicePay.paymentAmount.substring(1)
            );
            this.totalInvoiceAmount = paymentAmount.reduce(this.sumOfAmount, 0);
          }
        }
      }
    });
    this.getCardDetails();
    const TWO = 2;
    this.addCardSubscription = this.addCardService.cardAdded.subscribe((res1) => {
      if (res1) {
        this.getCardDetails();
        const value = res1.cardNumber.split('-');
        const cardDetails = {
          displayCardNumber: `xxxx-xxxx-xxxx-xxxx-${value[TWO]}`,
          cardType: res1?.cardType?.code,
          cardNumber: res1?.cardNumber,
          creditCardName: res1?.accountHolderName,
          validTo: res1?.validTo
        };
        this.temporaryCardDetails = cardDetails;
        this.sharedService.setSessionStorage('selectedCard', cardDetails);
        this.addCardService.cardAdded.next(false);
      }
    });
  }
  getCardDetails() {
    this.userSubscription = this.user.get().subscribe((resp) => {
      if (!resp == false) {
        this.userData = resp;
        if (this.userData?.userName) {
          this.accountIdSubscription = this.makeAPayment.getAccountId(this.userData?.userName).subscribe((res) => {
            this.b2bUnitID = res?.defaultB2BUnit?.uid;
            this.cardDetailsSubscription = this.makeAPayment
              .getCardDetails({
                accountId: this.b2bUnitID,
              })
              .subscribe((res1) => {
                this.formatCardData = InvoiceUtils.formatCardData(
                  res1.sanofiPaymentsResponseList
                );
                if (this.formatCardData.length !== 0) {
                  const data = this.formatCardData.filter((item) => item.creditCardDefault === true);
                  this.selectedCard = data[0].displayCardNumber;
                  this.existingCard = data[0];
                  if (data[0].cardExpired) {
                    this.translateSubscription = this.translation.translate(this.cardApi).subscribe((resp1) => {
                      this.globalMessage.add(resp1,
                        GlobalMessageType.MSG_TYPE_ERROR
                      );
                    });
                  }
                  this.sharedService.setSessionStorage('selectedCard', data[0]);
                  if (this.isAsmEnabled) {
                    this.sharedService.setLocalStorage('selectedCard', data[0]);
                  }
                }
                this.cdr.detectChanges();
              });
            if (this.isAsmEnabled) {
              this.makeAPayment.getEmails(this.userData?.userName, this.b2bUnitID);
              this.emailSubscription = this.makeAPayment.emails$.subscribe((emailsList) => {
                if (emailsList) {
                  this.emailsArray = [];
                  this.emailsArray = emailsList;
                  if (!this.emailsArray?.includes('Add Other E-mail Address')) {
                    this.emailsArray.push('Add Other E-mail Address');
                  }
                  this.makeAPayment.emails.next(false);
                }

              });
            }
          });
        }
      }
    });
  }
  cardChange(param) {
    this.existingCard = param;
    this.sharedService.setSessionStorage('selectedCard', param);
    if (this.isAsmEnabled) {
      this.sharedService.setLocalStorage('selectedCard', param);
    }
    if (param?.cardExpired) {
      this.translateSubscription = this.translation.translate(this.cardApi).subscribe((res) => {
        this.globalMessage.add(res,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
    }
  }
  Continue(pageType): any {
    this.submitted = true;

    // stop here if form is invalid
    if (this.creditPaymentForm.invalid) {
      return;
    }
    if (pageType === 'Invoice') {
      const newCardButton = document.getElementById('new_card') as HTMLInputElement;
      if (newCardButton?.checked) {
        if (this.temporaryCardDetails === null) {
          this.translateSubscription = this.translation.translate('addToCartItems.invalidPayment').subscribe((res) => {
            this.globalMessage.add(
              res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
          return;
        }
        this.sharedService.setSessionStorage('selectedCard', this.temporaryCardDetails);
      } else {
        if (this.formatCardData === undefined || this.formatCardData.length === 0 ||
          this.selectedCard === null || this.selectedCard === '') {
          this.translateSubscription = this.translation.translate('addToCartItems.invalidPayment').subscribe((res) => {
            this.globalMessage.add(
              res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
          return;
        }
        this.sharedService.setSessionStorage('selectedCard', this.existingCard);
      }
      this.sharedService.setSessionStorage(
        'cardDetails',
        this.f.personName.value
      );
      if (this.isAsmEnabled) {
        this.sharedService.setLocalStorage('cardDetails', this.f.personName.value);
      }
    }
    const data = {
      selectedEmail: this.f1.emailId.value,
      newEmail: this.f1.newemailId.value
    };
    if (this.isAsmEnabled) {
      this.sharedService.setLocalStorage('asmEmail', data);
      this.sharedService.setLocalStorage('creditPersonName', this.f.personName.value);
      this.sharedService.setSessionStorage('asmEmail', data);
    }
    this.sharedService.setSessionStorage(
      'creditPersonName',
      this.f.personName.value
    );
    if (pageType === 'Credit') {
      this.router.navigate(['/openInvoicePaymentReview']);
    } else {
      if (this.selectedCard?.cardExpired) {
        this.translateSubscription = this.translation.translate(this.cardApi).subscribe((res) => {
          this.globalMessage.add(res,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        });
      } else {
        this.router.navigate(['/openInvoicePaymentReview']);
      }
    }
  }
  back(): void {
    this.router.navigate(['/my-account/invoices']);
  }
  // Addition of floating values
  sumOfAmount(sum, num): any {
    const data = num.replace(/,/g, '');
    return Number(sum) + parseFloat(data);
  }

  open() {
    const modref = this.modalService.open(AddCardComponent);
    modref.componentInstance.isCheckbox = true;
    this.addCardDetailsSubscription = this.addCardService.cardDetails.subscribe((res) => {
      if (res) {
        this.formatCardData = res;
        const data = this.formatCardData.filter((item) => item.creditCardDefault === true);
        this.selectedCard = data[0].displayCardNumber;
        this.existingCard = data[0];
        this.sharedService.setSessionStorage('selectedCard', data[0]);
        if (this.isAsmEnabled) {
          this.sharedService.setLocalStorage('selectedCard', data[0]);
        }
        this.cdr.detectChanges();
      }
    });
    this.cdr.detectChanges();
  }
  invoicePdf(invoiceNo): void {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  invoicePdfWithSystemId(invoiceNo, systemId): void {
    this.makeAPayment.downloadPdfWithSystemId(invoiceNo, systemId);
  }

  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }

  get f(): any {
    return this.creditPaymentForm.controls;
  }
  get f1(): any {
    return this.editStdForm.controls;
  }
  omit_special_char(event) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32);
  }

  creditPdf(creditNo): void {
    this.makeAPayment.downloadPdf(creditNo);
  }
  ngOnDestroy() {
    this.csAgentAuthSubscription?.unsubscribe();
    this.addCardSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
    this.accountIdSubscription?.unsubscribe();
    this.cardDetailsSubscription?.unsubscribe();
    this.translateSubscription?.unsubscribe();
    this.emailSubscription?.unsubscribe();
    this.addCardDetailsSubscription?.unsubscribe();
  }
}
