import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FacetService,
  ProductListComponentService,
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { CustomProdPgService } from '../custom-prod-pg.service';
import {AccountType} from "../../custom-user-registration/registration.constants";

@Component({
  selector: 'app-custom-product-page',
  templateUrl: './custom-product-page.component.html',
  styleUrls: ['./custom-product-page.component.scss'],
})
export class CustomProductPageComponent implements OnInit, OnDestroy {
  public productList$ = this.productListService.model$;
  pagination: any;
  currentPage = 1;
  facets$: any;
  pageRange: any;
  productLotSize: number;
  pagesize: number;
  userDetails: any;
  selectedData: any;
  prodListSub: Subscription;
  facetListSub: Subscription;
  usedDataSub: Subscription;
  hospital:boolean;
  constructor(
    private readonly productListService: ProductListComponentService,
    private readonly user: UserAccountFacade,
    private readonly plpService: CustomProdPgService,
    private readonly facet: FacetService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    this.prodListSub = this.productList$.subscribe((res) => {
      this.pagesize = res.pagination.pageSize;
      this.productLotSize = res.products.length;
      this.pagination = res.pagination;
      this.selectedData = res?.sorts?.find(
        (item) => item.code === 'relevance'
      ).name;
      this.performPagination(this.pagination.currentPage);
      this.dataLayerService.viewListItemsDataLayer(res, 'standard process');
    });

    this.facetListSub = this.facet.facetList$.subscribe((res) => {
      this.facets$ = res.activeFacets;
    });
    this.usedDataSub = this.user.get().subscribe((res) => {
     
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.plpService.getCarts(this.userDetails?.userName);
      }
    });
  }
  sortList(event): void {
    this.productListService.sort(event); 
  }
  
  isIndirectPage(): boolean {
    return window.location.href.indexOf('productType:INDIRECT') != -1;
  }
  
  performPagination(data): void {
    const event = Number(data);
    this.currentPage = event + 1;
    const firstRange = event * this.pagesize + 1;
    const secondRange = (event + 1) * this.pagesize;
    const previousValue = this.pagination.totalResults - event * this.pagesize;
    if (previousValue < this.pagesize) {
      this.pageRange = `${firstRange}-${previousValue + event * this.pagesize}`;
    } else {
      this.pageRange = `${firstRange}-${secondRange}`;
    }
  }
  ngOnDestroy() {
    this.prodListSub?.unsubscribe();
    this.facetListSub?.unsubscribe();
    this.usedDataSub?.unsubscribe();
  }
}
