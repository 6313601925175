export const siteLanguagesEng = 'en';
export const siteLanguagesFr = 'fr';
export const globalErrorMsgEng = 'Please correct the errors below.';
export const globalErrorMsgFr = 'Merci de corriger les erreurs ci-dessous';
export const passwordErrorMsgEng = 'Please correct the error with password';
export const passwordErrorMsgFr = 'Merci de fournir un mot de passe valide';
export enum AccountType {
    Hospital = "hospital",
    Pharmacy = "pharmacy",
}
