import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { environment } from '../../../../environments/environment';
import { CustomPdpService } from '../../../custom-pdp/custom-pdp.service';
import { AccountType } from "../../../custom-user-registration/registration.constants";
import { CustomAddToCartService } from '../../custom-add-to-cart.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  @Input() public productDetails;
  @Input() public standardCartCode;
  @Input() public unitSection;
  description: any;
  monograph: any;
  monographLink: any;
  itemAddedQuantity: any;
  public baseUrl = environment.occBaseUrl;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  userDetails: any;
  cartId: any;
  cartItems: any;
  volumePrices: any;
  prodCodeSub: Subscription;
  pdpCartItemsSub: Subscription;
  allCartItemSub: Subscription;
  cartDetailsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartDetailsSub: Subscription;
  wrongQuantity: boolean = false;
  hospital: boolean;
  boxesQuantity = 0;
  numberOfDosesPerUnit: number;
  userInput: string = '';
  roundedValue: number | null = null;
  dividedValue: number | null = null;

  constructor(
    private readonly addToCart: CustomAddToCartService,
    private readonly modalService: NgbModal,
    private readonly pdpService: CustomPdpService,
    private readonly user: UserAccountFacade,
    private readonly sanitizer: DomSanitizer,
    private readonly dataLayerService: DataLayerService,
    private globalMessage: GlobalMessageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translation: TranslationService
  ) {}

  ngOnInit(): void {
    let calculatedQty = Math.ceil(this.productDetails?.product?.quantityPerUnit / this.productDetails?.product?.numberOfDosesPerUnit);
    this.boxesQuantity =  calculatedQty=== 0 ? 1 : calculatedQty
    const quantityInCart = this.productDetails?.quantity || this.cartItems?.quantity;
      this.roundedValue = quantityInCart;
    this.hospital = sessionStorage.getItem('accountType') == AccountType.Hospital;
    this.pdpService.productCode.next(false);
    this.pdpService.getPDPCategory(this.productDetails?.product?.code);

    this.prodCodeSub = this.pdpService.productCode$.subscribe((res) => {
      if (res) {
        this.volumePrices = res?.volumePrices;
      } else {
        this.volumePrices = [];
      }
    });

    this.description = this.productDetails?.product?.description;
    this.monograph = this.productDetails?.product?.productDocuments;
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          link.url
        );
      }
    }
    this.user.get().subscribe((res) => {
      if (res) {
        this.userDetails = res;
        if (this.userDetails) {
          this.pdpService.getCarts(this.userDetails?.userName);
        }
      }
    });
    this.pdpCartItemsSub = this.pdpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartDataMapping(res);
      }
    });
    this.allCartItemSub = this.addToCart.allCartItems$.subscribe((res) => {
      if (res) {
        this.cartDataMapping(res);
      }
    });
    this.dataLayerService.viewItemDataLayer(
      this.productDetails?.product,
      'standard process'
    );
  }

  cartDataMapping(response) {
    this.cartItems = response?.carts;
    this.cartItems = this.cartItems.filter(
      (item) => item?.saveTime === undefined
    );
    this.cartItems = this.cartItems[this.cartItems?.length - 1];
    this.cartId = this.cartItems?.code;
  }
  performQuantityComputation(
    product,
    operator,
    idData,
    stepQuantity,
    minQuantity,
    maxQuantity,
  ): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;
    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'category',
        product?.product?.numberOfDosesPerUnit
      );
      const inputElement = document.getElementById(`prodQuantity${product.code}`) as HTMLInputElement;
                     this.userInput = inputElement.value; // Update userInput

                     // Always call the rounding logic after updating the quantity
                     this.roundToNearestMultipleOfStepQuantity(stepQuantity);
                     this.updateButtonStates(stepQuantity);
                     this.cdr.detectChanges();
    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }
  updateCart(idData, popover): void {
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = [
      {
        entryNumber: this.productDetails?.entryNumber,
        quantity: inputElement.value,
      },
    ];

    this.addToCart.updateCartDetails(
      this.standardCartCode,
      params,
      this.cartId
    );

    this.cartDetailsSub = this.addToCart.cartDetails$.subscribe((res: any) => {
      if (res) {
        this.successPopoverDisplay(popover);
      }
    });
  }
  close(): void {
    this.modalService.dismissAll();
  }
  addToSessionCart(idData, productCode, popover): void {
    const inputElement = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const params = {
      product: {
        code: productCode,
      },
      quantity: inputElement.value,
    };
    this.itemAddedQuantity = inputElement.value;

    if (this.cartItems && this.cartItems?.length === 0) {
      this.pdpService.createCart(this.userDetails?.userName);
      this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
        if (res) {
          this.cartId = res?.code;
          this.pdpService.getCarts(this.userDetails?.userName);
          this.updateCartDetails(params, popover);
        }
      });
    } else {
      if (this.cartId === undefined) {
        this.cartItems =
          this.cartItems && this.cartItems?.length > 0
            ? this.cartItems[this.cartItems?.length - 1]
            : [];
        this.cartId = this.cartItems?.code;
      }
      this.updateCartDetails(params, popover);
    }
  }
  updateCartDetails(params, popover) {
    this.pdpService.updateCart(
      params,
      this.userDetails?.userName,
      this.cartId,
      null
    );
    this.updatedCartDetailsSub = this.pdpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          this.successPopoverDisplay(popover);
        }
      }
    );
  }
  successPopoverDisplay(popover) {
    const num = 2500;
    popover.toggle();
    setTimeout(() => popover.close(), num);
  }

  roundToNearestMultipleOfStepQuantity(stepQuantity: number) {
        const parsedInput = parseFloat(this.userInput);
        if (!isNaN(parsedInput) && parsedInput > 0) {
          if (parsedInput % stepQuantity !== 0) {
            this.roundedValue = Math.ceil(parsedInput / stepQuantity) * stepQuantity;
          } else {
            this.roundedValue = parsedInput;
          }

          if (this.roundedValue !== null) {
            this.userInput = this.roundedValue.toString();
            this.dividedValue = this.roundedValue / stepQuantity;
          }

          // Enable/disable the minus button based on the current value
          this.updateButtonStates(stepQuantity);
        } else {
          // Handle invalid input
          this.roundedValue = null;
          this.dividedValue = null;
        }
        this.cdr.detectChanges();
      }

  updateButtonStates(stepQuantity: number): void {
        const inputElement = document.getElementById(`prodQuantity${this.productDetails.code}q`) as HTMLInputElement;
        const quantity = parseInt(inputElement.value, 10) || 0;

        const minusButton = document.getElementById(`minusIcon${this.productDetails.code}q`);
        const plusButton = document.getElementById(`plusIcon${this.productDetails.code}q`);

        // Enable minus button if quantity is greater than the minimum step quantity
        if (quantity > stepQuantity) {
          minusButton?.classList.remove('disabled');
        } else {
          minusButton?.classList.add('disabled');
        }

        // Enable plus button if quantity is less than some max value (if applicable)
        if (quantity < 9999) {
          plusButton?.classList.remove('disabled');
        } else {
          plusButton?.classList.add('disabled');
        }
      }

  ngOnDestroy() {
    this.prodCodeSub?.unsubscribe();
    this.prodCodeSub?.unsubscribe();
    this.pdpCartItemsSub?.unsubscribe();
    this.allCartItemSub?.unsubscribe();
    this.cartDetailsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.updatedCartDetailsSub?.unsubscribe();
  }
}
