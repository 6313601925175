<div class="acc-overviewtabs">
  <div class="row table-sanofi">
    <div class="col-md-4">
      <h3 class="financial">
        {{ "accountOverview.Financials.label" | cxTranslate }}
      </h3>
    </div>
    <div class="col-md-8 nav-alignment">
      <div class="prodCard-tabs tab_right_float">
        <ul class="prodCard-list">
          <li class="tab-link" [ngClass]="{ active: selectedTab === 'HISTORICAL' }" data-tab="tab_1"
              (click)="tabNavigation('HISTORICAL')">
            {{ "accountOverview.Financials.historical_label" | cxTranslate }}
          </li>

          <li class="tab-link" [ngClass]="{ active: selectedTab === 'INVOICES' }" data-tab="tab_1"
              (click)="tabNavigation('INVOICES')">
            {{ "accountOverview.Financials.invoice_label" | cxTranslate }}
          </li>

          <li class="tab-link" [ngClass]="{ active: selectedTab === 'CREDITS' }" data-tab="tab_3"
              (click)="tabNavigation('CREDITS')">
            {{ "accountOverview.Financials.credits_label" | cxTranslate }}
          </li>
          <li class="tab-link" [ngClass]="{ active: selectedTab === 'STATEMENTS' }" data-tab="tab_4"
              (click)="tabNavigation('STATEMENTS')">
            {{ "accountOverview.Financials.statements_label" | cxTranslate }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div id="tab_1" class="tab_content current">
    <app-loader *ngIf="!isLoadingDone && selectedTab !== 'STATEMENTS'"></app-loader>
    <div class="text-center no-invoices" *ngIf="
        !!historicalDataList &&
        historicalDataList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'HISTORICAL'
      ">
      {{ "accountOverview.Financials.no_historical_data_found" | cxTranslate }}
    </div>
    <div class="text-center no-invoices" *ngIf="
        !!paymentList &&
        paymentList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      ">
      {{ "accountOverview.Financials.no_invoice_found" | cxTranslate }}
    </div>
    <div class="text-center no-credits" *ngIf="
        !!creditList &&
        creditList.length === 0 &&
        isLoadingDone &&
        selectedTab === 'CREDITS'
      ">
      {{ "accountOverview.Financials.no_credit_found" | cxTranslate }}
    </div>
    <div class="row xls-pdf-invoice" *ngIf="showXlsPdf && isLoadingDone && selectedTab !== 'STATEMENTS'">
      <div class="col-md-12 ad-files-text darkblue full-wd">
        <a title="XLS" id="invoiceXLS" aria-label="download account overview invoices as" data-di-id="#invoiceXLS">
          <img src="../../assets/icons/excel.png" alt="" class="ad-pdf" target="_blank" />
          <span class="acc-icon-text" (click)="generateExcel()">{{
            "accountOverview.Financials.excel_label" | cxTranslate
            }}</span></a>
        <a id="invoicePDF" aria-label="download account overview invoices as" data-di-id="#invoicePDF">
          <img src="../../assets/icons/pdf.svg" alt="PDF" class="ad-pdf" />
          <span class="acc-icon-text" (click)="generatePDF()">{{
            "accountOverview.Financials.pdf_label" | cxTranslate
            }}</span>
        </a>
      </div>
    </div>


    <!--HISTORICAL DATA TABLE SECTION -->
    <section class="row">
      <section class="col-md-12 col-xs-12 col-sm-12 fin-Table-Pad" id="closed-invoice-table">
        <section class="payment-section" *ngIf="
            !!historicalDataList &&
            historicalDataList.length > 0 &&
            isLoadingDone &&
            selectedTab === 'HISTORICAL'
          ">
          <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped"
                 [ngClass]="{ 'not-pdf': isPDF === false }">
            <thead>
            <tr>
              <th scope="col" id="docDate" (click)="sortColumn($event, 'docDate', historicalDataList, 'normal')">
                {{
                "accountOverview.Financials.historical_table.doc_date"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" id="dueDate" (click)="sortColumn($event, 'dueDate', historicalDataList, 'Date')">
                {{
                "accountOverview.Financials.historical_table.doc_closing_date"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" id="docType" (click)="sortColumn($event, 'docType', historicalDataList, 'Date')">
                {{
                "accountOverview.Financials.historical_table.doc_type" | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" class="text-right" id="docNo"
                  (click)="sortColumn($event, 'docNo', historicalDataList, 'Number')">
                {{
                "accountOverview.Financials.historical_table.doc_no"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>

              <th scope="col" class="text-right" id="amount"
                  (click)="sortColumn($event, 'amount', historicalDataList, 'normal')">
                {{
                "accountOverview.Financials.historical_table.total_amount"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of historicalDataList; let i = index">
              <td class="pay">{{ data?.docDate }}</td>
              <td class="pay">
                <span>{{ data?.clearDate }}</span>
              </td>
              <td class="two-column-lay">
                  <span *ngIf="data?.dbCrInd === 'H'">
                    {{ "accountOverview.Financials.credits_label" | cxTranslate }}
                  </span>
                <span *ngIf="data?.dbCrInd === 'S'">
                    {{ "accountOverview.Financials.invoice_label" | cxTranslate }}
                  </span>
              </td>
              <td class="pay">
                <a *ngIf="
                      (data?.docType === 'RV' ||
                      data?.docType === 'RD' ||
                      data?.docType === 'RF')"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refDocNo, data.systemId)">
                  {{ data.refDocNo }}
                </a>
                <a *ngIf="
                      data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.dochdrtxt, data.systemId)">
                  {{ data.dochdrtxt }}
                </a>
                <span *ngIf="
                      data?.docType === 'DR' ||
                      data?.docType === 'DG' ||
                      data?.docType === 'DY' || data?.docType === '$D'
                    ">
                    {{ data.refDocNo }}
                  </span>
                <span *ngIf="
                      data?.docType === 'DZ' &&
                      !data?.dochdrtxt?.startsWith('7')
                    ">
                    {{ data.dochdrtxt }}
                  </span>
                <span *ngIf="data?.docType === 'YR'">
                    {{ data.refhdr }}
                  </span>
              </td>
              <td class="pay inv-text-end amount-Wrap">
                {{ data?.amount | invoiceCurrency }}
              </td>

            </tr>
            </tbody>
          </table>

          <table aria-describedby="invoicesTable" id="applyInvoiceRWD" class="payments-table table table-striped"
                 [ngClass]="{ 'not-pdf': isPDF === false }">
            <tr class="sample-header">
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr *ngFor="let data of historicalDataList; let i = index">
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.date_label" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.docDate }}</td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.invoice_no" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <a *ngIf="
                    (data?.docType === 'RV' ||
                    data?.docType === 'RD' ||
                    data?.docType === 'RF')"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refDocNo, data.systemId)">
                  {{ data.refDocNo }}
                </a>
                <a *ngIf="
                    data?.docType === 'DZ' && data?.dochdrtxt?.startsWith('7')"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.dochdrtxt, data.systemId)"
                >
                  {{ data.dochdrtxt }}
                </a>
                <span *ngIf="
                    data?.docType === 'DR' ||
                    data?.docType === 'DG' ||
                    data?.docType === 'DY' || data?.docType === '$D'
                  ">
                  {{ data.refDocNo }}
                </span>
                <span *ngIf="
                    data?.docType === 'DZ' && !data?.dochdrtxt?.startsWith('7')
                  ">
                  {{ data.dochdrtxt }}
                </span>
                <span *ngIf="data?.docType === 'YR'">
                  {{ data.refhdr }}
                </span>
              </td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.due_date" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <span [ngClass]="{
                    red: !!data?.dueDate && dateComparison(data?.dueDate)
                  }">{{ data?.dueDate }}
                  <ng-template #popSaveContent>
                    <span class="popover-content">{{
                      "makeAPayment.past_due_date" | cxTranslate
                      }}</span>
                  </ng-template>
                  <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                        (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                        *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)">
                    <img src="assets/icons/warning_red.png" alt="warningIcon" class="warningIconEvent" />
                  </span>
                </span>
              </td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.amount_due" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.amount | localCurrency }}</td>
            </tr>
          </table>
        </section>
      </section>
    </section>


    <!--INVOCE TABLE SECTION -->
    <section class="row">
      <section class="col-md-12 col-xs-12 col-sm-12 fin-Table-Pad" id="invoice-table">
        <section class="payment-section" *ngIf="
            !!paymentList &&
            paymentList.length > 0 &&
            isLoadingDone &&
            selectedTab === 'INVOICES'
          ">
          <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped"
                 [ngClass]="{ 'not-pdf': isPDF === false }">
            <thead>
            <tr>
              <th scope="col" id="docNo" (click)="sortColumn($event, 'docNo', paymentList, 'normal')">
                {{
                "accountOverview.Financials.invoices.invoice_no"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" id="docDate" (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                {{
                "accountOverview.Financials.invoices.date_label"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" id="dueDate" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                {{
                "accountOverview.Financials.invoices.due_date" | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <th scope="col" class="text-right" id="amount"
                  (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                {{
                "accountOverview.Financials.invoices.invoice_amount"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              <!--
              <th scope="col" class="text-right" id="amountDue"
                (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                {{
                "accountOverview.Financials.invoices.amount_due"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
              -->
              <th scope="col" class="text-right" id="orderNumber"
                  (click)="sortColumn($event, 'orderNumber', paymentList, 'normal')">
                {{
                "accountOverview.Financials.invoices.order_number"
                | cxTranslate
                }}
                &nbsp;
                <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of paymentList; let i = index">
              <td class="pay">
                <a *ngIf="
                      (data?.docType.startsWith('R'))"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refDocNo, data.systemId)">
                  {{ data.refDocNo }}
                </a>
                <span *ngIf="
                      data?.docType.startsWith('D')
                    ">
                    {{ data.refDocNo }}
                  </span>
                <a *ngIf="data?.docType === 'YR' && !hospital &&
                            (data?.dochdrtxt.startsWith('FR50') ||
                             data?.dochdrtxt.startsWith('1888'))"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refhdr, data.systemId)">
                  {{ data.refhdr }}
                </a>
                <span *ngIf="data?.docType === 'YR' && hospital &&
                            (data?.dochdrtxt.startsWith('FR50') ||
                             data?.dochdrtxt.startsWith('1888'))">
                    {{ data.refhdr }}
                </span>
              </td>
              <td class="pay">{{ data?.docDate }}</td>
              <td class="pay">
                  <span [ngClass]="{
                      red: !!data?.dueDate && dateComparison(data?.dueDate)
                    }">{{ data?.dueDate }}
                    <ng-template #popSaveContent>
                      <span class="popover-content">{{
                        "makeAPayment.past_due_date" | cxTranslate
                        }}</span>
                    </ng-template>
                    <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                          (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                          *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)">
                      <img src="assets/icons/warning_red.png" alt="warningIcon" class="warningIconEvent" />
                    </span>
                  </span>
              </td>
              <!-- <td class="pay inv-text-end amount-Wrap">
                {{ data?.amount | localCurrency }}
              </td> -->
              <td class="pay inv-text-end amount-Wrap">
                {{ data?.amount | invoiceCurrency }}
              </td>
              <td class="two-column-lay">{{ data?.salesOrder }}</td>
            </tr>
            </tbody>
          </table>
          <table aria-describedby="invoicesTable" id="applyInvoiceRWD" class="payments-table table table-striped"
                 [ngClass]="{ 'not-pdf': isPDF === false }">
            <tr class="sample-header">
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            <tr *ngFor="let data of paymentList; let i = index">
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.date_label" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.docDate }}</td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.invoice_no" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <a *ngIf="
                    data?.docType.startsWith('R')"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refDocNo, data.systemId)">
                  {{ data.refDocNo }}
                </a>
                <span *ngIf="
                    data?.docType.startsWith('D')
                  ">
                  {{ data.refDocNo }}
                </span>
                <a *ngIf="data?.docType === 'YR' && !hospital &&
                          (data?.dochdrtxt.startsWith('FR50') ||
                           data?.dochdrtxt.startsWith('1888'))"
                   href="javascript: void(0);"
                   class="underline"
                   (click)="invoicePdf(data.refhdr, data.systemId)">
                  {{ data.refhdr }}
                </a>
                <span *ngIf="data?.docType === 'YR' && hospital &&
                          (data?.dochdrtxt.startsWith('FR50') ||
                           data?.dochdrtxt.startsWith('1888'))">
                  {{ data.refhdr }}
                </span>
              </td>
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.due_date" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">
                <span [ngClass]="{
                    red: !!data?.dueDate && dateComparison(data?.dueDate)
                  }">{{ data?.dueDate }}
                  <ng-template #popSaveContent>
                    <span class="popover-content">{{
                      "makeAPayment.past_due_date" | cxTranslate
                      }}</span>
                  </ng-template>
                  <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                        (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                        *ngIf="!!data?.dueDate && dateComparison(data?.dueDate)">
                    <img src="assets/icons/warning_red.png" alt="warningIcon" class="warningIconEvent" />
                  </span>
                </span>
              </td>
              <!-- <td class="two-column-lay header">
                {{
                  "accountOverview.Financials.invoices.invoice_amount"
                    | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.amount | localCurrency }}</td> -->
              <td class="two-column-lay header">
                {{
                "accountOverview.Financials.invoices.amount_due" | cxTranslate
                }}
              </td>
              <td class="two-column-lay">{{ data?.amount | localCurrency }}</td>
            </tr>
          </table>
        </section>
      </section>
    </section>
    <section id="pdf-dummy" *ngIf="isPDF"></section>

    <!-- CREDITS TABLE SECTION -->
    <section *ngIf="!!creditList && creditList.length > 0 && isLoadingDone && selectedTab === 'CREDITS'" id="credits-table" class="credits-section">
      <table aria-describedby="creditsTable" id="applyCreditsTable" class="credits-table table table-striped" [ngClass]="{ 'not-pdf': isPDF === false }">
        <thead>
        <tr>
          <th scope="col" class="col-width" id="creditNoteNumber" (click)="sortColumn($event, 'refDocNo', creditList, 'normal')">
            {{ "makeAPayment.credit_note_number" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th scope="col" id="creditDate" (click)="sortColumn($event, 'docDate', creditList, 'Date')">
            {{ "makeAPayment.credit_date" | cxTranslate }}
            <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
          </th>
          <th scope="col" class="text-left" id="creditAmt" (click)="sortColumn($event, 'amount', creditList, 'Number')">
            {{ "makeAPayment.credit_amount" | cxTranslate }}
            <i class="my-icon fas fa-caret-down" *ngIf="!isPDF"></i>
          </th>
          <th scope="col" class="text-align col-width" id="creditOrderNumber" (click)="sortColumn($event, 'salesOrder', creditList, 'normal')">
            {{ "makeAPayment.orderNumber" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of creditList; let i = index">
          <td class="pay">
            <a *ngIf="data?.docType.startsWith('R')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
              {{ data.refDocNo }}
            </a>
            <span *ngIf="data?.docType.startsWith('D')">
            {{ data.refDocNo }}
          </span>
            <a *ngIf="data?.docType === 'YR' && data?.dochdrtxt.startsWith('FR50')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refhdr, data.systemId)">
              {{ data.refhdr }}
            </a>
            <a *ngIf="data?.docType === 'YR' && data?.dochdrtxt.startsWith('1888')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refhdr, data.systemId)">
              {{ data.refhdr }}
            </a>
          </td>
          <td>{{ data?.docDate }}</td>
          <td class="text-left">{{ data?.amount | invoiceCurrency }}</td>
          <td class="amount-align">{{ data?.salesOrder }}</td>
        </tr>
        </tbody>
      </table>

      <table aria-describedby="creditsTable" id="applyCreditsRWD" class="credits-table table table-striped" [ngClass]="{ 'not-pdf': isPDF === false }">
        <tr class="sample-header">
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr *ngFor="let data of creditList; let i = index">
          <td class="two-column-lay header">
            {{ "makeAPayment.credit_note_number" | cxTranslate }}
          </td>
          <td class="two-column-lay">
            <a *ngIf="data?.docType.startsWith('R')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
              {{ data.refDocNo }}
            </a>
            <span *ngIf="data?.docType.startsWith('D')">
          {{ data.refDocNo }}
        </span>
            <a *ngIf="data?.docType === 'YR' && data?.dochdrtxt.startsWith('FR50')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refhdr, data.systemId)">
              {{ data.refhdr }}
            </a>
            <a *ngIf="data?.docType === 'YR' && data?.dochdrtxt.startsWith('1888')" href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refhdr, data.systemId)">
              {{ data.refhdr }}
            </a>
          </td>
          <td class="two-column-lay header">
            {{ "makeAPayment.credit_date" | cxTranslate }}
          </td>
          <td class="two-column-lay">{{ data?.docDate }}</td>
          <td class="two-column-lay header">
            {{ "makeAPayment.credit_amount" | cxTranslate }}
          </td>
          <td class="two-column-lay text-left">
            {{ data?.amount | invoiceCurrency }}
          </td>
          <td class="two-column-lay header">
            {{ "makeAPayment.orderNumber" | cxTranslate }}
          </td>
          <td class="two-column-lay text-left">{{ data?.salesOrder }}</td>
        </tr>
      </table>
    </section>



    <section *ngIf="selectedTab === 'STATEMENTS'">
      <app-open-statements [hideAside]="removeAside"></app-open-statements>
    </section>
    <div class="invoice-btngrps" *ngIf="
        !!historicalDataList &&
        historicalDataList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'HISTORICAL'
      ">
    </div>
    <div class="invoice-btngrps" *ngIf="
        !!paymentList &&
        paymentList.length > 0 &&
        isLoadingDone &&
        selectedTab === 'INVOICES'
      ">
    </div>
    <div class="invoice-btngrps" *ngIf="
        isLoadingDone &&
        selectedTab !== 'STATEMENTS'
      ">
      <button type="button" class="btn btn-primary oi-blue-button btnpaymentinvoicecredit"
        data-di-id="di-id-41a05d18-6b04a424" [routerLink]="['/my-account/invoices']">
        {{ "accountOverview.Financials.invoicePageButtonText" | cxTranslate }}
      </button>
    </div>
    <div class="invoice-btngrps" *ngIf="selectedTab === 'STATEMENTS' && !!isLoadingDone">
      <button type="button" class="btn btn-primary statement oi-blue-button btnpaymentinvoicecredit"
              data-di-id="di-id-41a05d18-6b04a424" [routerLink]="['/my-account/statements']">
        {{
        "accountOverview.Financials.statements.statement_btn_txt"
        | cxTranslate
        }}
      </button>
    </div>
  </div>
</div>
<ng-template #invoicePastDueDateMsg>{{
  "accountOverview.Financials.pdf_label" | cxTranslate
  }}</ng-template>
<div class="hide-section" *ngIf="isPDF">
  <div class="col-md-12" id="pdf-header">
    <h3 class="financial">
      {{ "accountOverview.Financials.label" | cxTranslate }}
    </h3>
  </div>
  <div class="col-md-12" id="pdf-subheader">
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'HISTORICAL'">
      {{ "accountOverview.Financials.historical_label" | cxTranslate }}
    </h3>

    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'INVOICES'">
      {{ "accountOverview.Financials.invoice_label" | cxTranslate }}
    </h3>
    <h3 class="sub-label-pdf" *ngIf="selectedTab === 'CREDITS'">
      {{ "accountOverview.Financials.credits_label" | cxTranslate }}
    </h3>
  </div>
  <div class="line-top-sanofi-pdf" id="line-sanofi"></div>
</div>
