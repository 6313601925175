import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { SubscriptionsContainer } from 'src/app/shared/utils/subscriptions-container';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';

@Component({
  selector: 'app-schedule-to-ship',
  templateUrl: './schedule-to-ship.component.html',
})
export class ScheduleToShipComponent implements OnInit, OnDestroy {
  header: any = 'scheduleToShip';
  compName = 'SanofiFluDashboardBannerComponent';
  userId: any;
  subs = new SubscriptionsContainer();
  shippinInfosub: Subscription;
  stsDataSub: Subscription;
  schedToShipForm = new FormGroup({
      forms: new FormArray([])
    });
  proceed: boolean = true;
  stsMessage: string = '';
  calculatedDataSchedule: any;
  cartsId: any;
  productSchedule: any;
  showSpinnerBtn: boolean = false;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';

  constructor(
    private customInfluenzaService: CustomInfluenzaService,
    private router: RoutingService,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    const user = sessionStorage.getItem('userId');
    if (user) {
      this.userId = user;
      this.customInfluenzaService.getCartIdofUser(user);
      this.subs.add = this.customInfluenzaService.cartIdActiveOfUser.subscribe(
        (cart) => {
          cart.carts.forEach((cartsData) => {
            cartsData.childCarts.forEach((childCartsData)=>{
              if(childCartsData.cartType == "INFLUENZA" && cartsData.code ){
                this.cartsId = cartsData.code
              }
            })
          });
          if (this.cartsId) {
            this.customInfluenzaService.getScheduleToShip(user, this.cartsId);
            this.stsDataSub =
              this.customInfluenzaService.scheduleToShipData.subscribe(
                (stsData) => {
                  this.stsMessage = stsData.stsMessage;
                  this.productSchedule = this.scheduleMin(stsData);
                  stsData?.productSchedules.forEach((schedule, index) => {
                    let scheduleMap = new Map();
                    scheduleMap.set(schedule?.deliveries[0]?.week, schedule?.deliveries[0]?.requestedQuantity);
                    scheduleMap.set(schedule?.deliveries[1]?.week, schedule?.deliveries[1]?.requestedQuantity)
                    scheduleMap.set(schedule?.deliveries[2]?.week, schedule?.deliveries[2]?.requestedQuantity)
                    this.schedToShipForm.controls.forms.push(new FormGroup({
                      week: new FormControl(),
                      weekone: new FormControl(scheduleMap.get('W0')),
                      weektwo: new FormControl(scheduleMap.get('W2')),
                      weekthree: new FormControl(scheduleMap.get('W4'))
                    }));
                  });
                  this.loadFormWithDefaultValues();
                  this.checkProductWeekToRedirect(
                    this.productSchedule,
                    '/influenza/checkout'
                  );
                  this.ref.detectChanges();
                  this.stsDataSub?.unsubscribe();
                }
              );
          }
        }
      );
    }
  }

  private checkProductWeekToRedirect(product, url) {
    //Checked products and redirect if all has 1 week
    let weekLength = [];
    product.map((product) => {
      weekLength.push(product.deliveries.length);
    });
    if (weekLength.every(this.checkWeekCount)) {
      this.customInfluenzaService.addSkipDeliverySchedule(true);
      this.router.goByUrl(url);
    }
    else {
      this.customInfluenzaService.addSkipDeliverySchedule(false);
    }
  }

  private scheduleMin(productData) {
    //Clone Schedule Data from Api
    return productData.productSchedules.map((product) => ({
      code: product.code,
      deliveries: product.deliveries,
      product: product.product,
      quantity: product.quantity,
    }));
  }

  loadFormWithDefaultValues(): void {
    let productSched = new Array();
    let counter = 0;
    let allRowsAligned = true;
    this.schedToShipForm.controls.forms.controls.forEach((form,index)=>{
      const productSchedulesTotalQuantity = this.productSchedule[index].quantity;
      const formTotalQuantity = (form as FormGroup).value.weekone + (form as FormGroup).value.weektwo
        + (form as FormGroup).value.weekthree
      if (productSchedulesTotalQuantity == formTotalQuantity){
        counter+=1;
        const elSelected = {
          code: this.productSchedule[index].code,
          deliveries: this.productSchedule[index].deliveries,
        };
        productSched.push(elSelected);
        this.productSchedule[index].status = true;
      } else {
        allRowsAligned = false;
      }
    })
    this.proceed = counter == this.schedToShipForm.controls.forms.controls.length && allRowsAligned;
    this.calculatedDataSchedule = {
      productSchedules: productSched,
    };
  }

  weekCalc(e, i, stepQuant, totalQuant, delWeek): void {
    let newData = this.productSchedule;
    let productQuantity = e.target.value;
    if (stepQuant != 1) {
      if (productQuantity < stepQuant && !(delWeek > 0 && productQuantity == 0)) {
        e.target.parentNode
          .querySelector('.value-less-notice')
          .classList.remove('hide');
      } else {
        e.target.parentNode
          .querySelector('.value-less-notice')
          .classList.add('hide');

        if (productQuantity % stepQuant == 0 || delWeek > 0 && productQuantity == 0) {
          e.target.parentNode.querySelector('.box-notice').classList.add('hide');
        } else {
          e.target.parentNode
            .querySelector('.box-notice')
            .classList.remove('hide');
        }
      }
    }

    // Add up all the input values of each product
    const allInputs = e.target.parentNode.parentNode.querySelectorAll('input');
    let val = 0;
    let quantityDifference;
    allInputs.forEach((input) => {
      if (input.value != '') {
        val += parseInt(input.value);
      }
    });

    // Check all the dozes to be delivered in all the weeks and assign a new object Status in the delivery array
    if (val == totalQuant) {
      document
        .getElementById(i + 'form')
        .querySelector('.total-notice')
        .classList.add('hide');
      newData[i].status = true;
      document
        .getElementById(i + 'form')
        .querySelector('.amount-difference').innerHTML = '';
    } else {
      document
        .getElementById(i + 'form')
        .querySelector('.total-notice')
        .classList.remove('hide');
      newData[i].status = false;
      //Check the difference and show in total notice the value
      if (val > totalQuant) {
        const valToShow = totalQuant - val;
        quantityDifference = `Retirez ${Math.abs(valToShow)} doses`;
        document
          .getElementById(i + 'form')
          .querySelector('.amount-difference').innerHTML = quantityDifference;
      } else {
        const valToShow = totalQuant - val;
        quantityDifference = `Reste ${Math.abs(valToShow)} doses à répartir`;
        document
          .getElementById(i + 'form')
          .querySelector('.amount-difference').innerHTML = quantityDifference;
      }
    }

    // Iterrate through schedule and check if delivery weeks match the total dozes - button disable/enable
    if (newData.every(this.checkWeekTotal)) {
      this.proceed = true;
    } else {
      this.proceed = false;
    }

    newData[i].deliveries[delWeek].requestedQuantity = parseInt(productQuantity);
    let productSched = new Array();
    newData.forEach((el) => {
      const elSelected = {
        code: el.code,
        deliveries: el.deliveries,
      };
      productSched.push(elSelected);
    });
    this.calculatedDataSchedule = {
      productSchedules: productSched,
    };

    this.ref.detectChanges();
  }

  private checkWeekTotal(data) {
    //helper to Iterrate through schedule and check if delivery weeks match the total dozes
    return data.status == true;
  }
  private checkWeekCount(data) {
    //validate the week is one - for redirecting
    return data == 1;
  }

  showPdp(prod): void {
    const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent, {
      windowClass: 'product-details-inf-modal',
    });
    modalRef.componentInstance.productDetails = prod;
    modalRef.componentInstance.standardCartCode = prod.code;
  }

  backToCart(): void {
    this.router.goByUrl('/influenza/cart');
  }

  print(): void {
    window.print();
  }

  goToCheckOut() {
    this.calculatedDataSchedule.productSchedules.forEach((element) => {
      element.deliveries.forEach((el) => {
        if (!el.requestedQuantity) {
          el.requestedQuantity = 0;
        }
      });
    });
    this.showSpinnerBtn = true;
    this.subs.add = this.customInfluenzaService
      .postcheduleToShip(this.userId, this.cartsId, this.calculatedDataSchedule)
      .subscribe((res) => {
        this.showSpinnerBtn = false;
        if (res == null) {
          this.customInfluenzaService.getCartIdofUser(this.userId);
          this.shippinInfosub =
            this.customInfluenzaService.cartIdActiveOfUser.subscribe(
              (cartData) => {
                if (cartData && cartData?.carts?.length > 0) {
                  let childInfluenzaCarts = [];
                  childInfluenzaCarts = cartData?.carts[0]?.childCarts;
                  if (childInfluenzaCarts) {
                    childInfluenzaCarts = childInfluenzaCarts.filter(
                      (item) => item.cartType === 'INFLUENZA'
                    );
                    this.dataLayerService.shippingInfo(
                      childInfluenzaCarts,
                      'new reservation'
                    );
                    this.shippinInfosub?.unsubscribe();
                    this.stsDataSub?.unsubscribe();
                  }
                }
              }
            );
          this.router.goByUrl('/influenza/checkout');
        }
      });
  }
  ngOnDestroy(): void {
    this.subs.dispose();
    this.shippinInfosub?.unsubscribe();
    this.stsDataSub?.unsubscribe();
  }
}
